import LogoIcon from "src/assets/LogoIcon";

export const NoMatch = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 120px)",
      }}
    >
      <LogoIcon style={{ overflow: "visible" }} />
    </div>
  );
};
