import { useEffect, createContext, useState, useContext } from "react";
import api from "../api";
import { useAuth } from "./auth";

type CustomerCustomerDetails = {
  ssn: string | null;
  dob: string | null;
};

type EnrollmentCase = {
  id?: string;
  address?: {
    address1: string;
    address2: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
  };
  bankAccount?: {
    id: number;
  };
  portalAuthCredential?: {
    portal: {
      loginUrl: string;
    };
    password: string;
    username: string;
  };
  legalEntity?: {
    companyName: string;
    contactFirstName: string;
    contactLastName: string;
    email: string;
    phone: string;
    canContact?: boolean;
  };
};

type EnrollmentCasesResponse = {
  object: "list";
  items: EnrollmentCase[];
  current_page: number;
  page_count: number;
  total_count: number;
  has_more: boolean;
  extra: unknown;
};

export const CustomerContext = createContext<{
  enrollmentCase: EnrollmentCase;
  customerDetails: {
    ssn: string | null;
    dob: string | null;
  };
  paymentInstruments: any[];
  fetchPaymentInstruments: () => Promise<void | unknown>;
  setPaymentInstruments: (paymentInstruments: any[]) => void;
  fetchCustomerDetails: () => void;
  fetchEnrollmentCases: () => Promise<void | unknown>;
  updateEnrollmentCase: (data: any) => Promise<void | unknown>;
  subscribeReportEnrollment: (data: {
    products: string[];
    enrollment_case_id: number;
    payment_method_id: number;
  }) => Promise<void | unknown>;
  setEnrollmentCaseAddress: (data: any) => Promise<void | unknown>;
  setEnrollmentCase: (data: any) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  refreshData: () => Promise<void | unknown>;
}>({
  setLoading: () => {},
  enrollmentCase: {},
  customerDetails: {
    ssn: null,
    dob: null,
  },
  paymentInstruments: [],
  fetchPaymentInstruments: () => Promise.resolve(),
  setPaymentInstruments: () => Promise.resolve(),
  fetchCustomerDetails: () => Promise.resolve(),
  fetchEnrollmentCases: () => Promise.resolve(),
  updateEnrollmentCase: () => Promise.resolve(),
  subscribeReportEnrollment: () => Promise.resolve(),
  setEnrollmentCaseAddress: () => Promise.resolve(),
  setEnrollmentCase: () => {},
  loading: false,
  refreshData: () => Promise.resolve(),
});

export const useCustomer = () => {
  return useContext(CustomerContext);
};
export function CustomerProvider({ children }) {
  const { authState, customer } = useAuth();
  const [customerDetails, setCustomerDetails] =
    useState<CustomerCustomerDetails>({
      ssn: null,
      dob: null,
    });
  const [enrollmentCase, setEnrollmentCase] = useState<EnrollmentCase>({});
  const [paymentInstruments, setPaymentInstruments] = useState([]);
  const [loading, setLoading] = useState(false);

  function fetchCustomerDetails() {
    return api
      .getCustomerDetails()
      .then(api.pickData)
      .then((resp) => setCustomerDetails(resp));
  }

  function fetchEnrollmentCases() {
    return api
      .getEnrollmentCases()
      .then(api.pickData)
      .then(({ items }: EnrollmentCasesResponse) => {
        if (items && items.length) {
          // THIS IS BS. Need to verify if we really treat active enrollment al first
          setEnrollmentCase({ ...items[0] });
        } else {
          setEnrollmentCase({});
        }
        return items;
      });
  }

  function fetchPaymentInstruments() {
    return api
      .getPaymentInstruments()
      .then(api.pickData)
      .then(({ items }) => {
        setPaymentInstruments(items);
      });
  }

  function updateEnrollmentCase(data) {
    return api
      .updateEnrollmentCase({
        id: enrollmentCase.id,
        ...data,
      })
      .then(fetchEnrollmentCases);
  }

  function subscribeReportEnrollment(data: {
    products: string[];
    enrollment_case_id: number;
    payment_method_id: number;
  }) {
    return api
      .checkout({
        ...data,
      })
      .then(api.pickData);
  }

  function setEnrollmentCaseAddress(data) {
    return fetchEnrollmentCases().then(() => {
      return api
        .setEnrollmentCaseAddress({
          id: data.id,
          ...data,
        })
        .then(api.pickData);
    });
  }

  const refreshData = () =>
    Promise.allSettled([fetchCustomerDetails(), fetchEnrollmentCases()]);

  useEffect(() => {
    refreshData();
  }, [authState]);

  return (
    <CustomerContext.Provider
      value={{
        enrollmentCase,
        customerDetails,
        paymentInstruments,
        fetchPaymentInstruments,
        setPaymentInstruments,
        fetchCustomerDetails,
        fetchEnrollmentCases,
        updateEnrollmentCase,
        subscribeReportEnrollment,
        setEnrollmentCaseAddress,
        setEnrollmentCase,
        loading,
        setLoading,
        refreshData,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
}
