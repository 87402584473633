import { config } from "./config";
import apiBase from "./misc/apiBase";

let token = localStorage.getItem("token");
let authHeader = token ? { Authorization: "Bearer " + token } : {};
const headersConfig = {
  headers: { "Content-Type": "application/json", ...authHeader },
};

let instance = apiBase.create(config.apiHost, headersConfig);

const refreshInstance = (token) => {
  let authHeader = token ? { Authorization: "Bearer " + token } : {};
  instance = apiBase.create(config.apiHost, {
    headers: { "Content-Type": "application/json", ...authHeader },
  });

  instance.interceptors.response.use((response) => {
    const { headers } = response;

    if (headers["x-api-key"]) {
      window?.localStorage?.setItem("token", headers["x-api-key"]);
      refreshInstance(headers["x-api-key"]);
    }

    return response;
  });
};

instance.interceptors.response.use((response) => {
  const { headers } = response;

  if (headers["x-api-key"]) {
    window?.localStorage?.setItem("token", headers["x-api-key"]);
    refreshInstance(headers["x-api-key"]);
  }

  return response;
});

const get = (path, params, opts) => {
  return instance.get(path, apiBase.mergeParams(params, opts));
};
const post = (path, params, opts) => {
  return instance.post(path, params, opts);
};
const patch = (path, params, opts) => {
  return instance.patch(path, params, opts);
};

const put = (path, params, opts) => {
  return instance.put(path, params, opts);
};

const del = (path, params, opts) => {
  return instance.delete(path, apiBase.mergeParams(params, opts));
};

const api = {
  get: get,
  post: post,
  put: put,
  delete: del,
  signOut: () => del("/v1/auth"),
  signIn: (data) =>
    post("/v1/auth", {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...data,
    }),
  signUp: (data) =>
    post("/v1/register", {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...data,
    }),
  forgotPassword: (data) => post("/v1/me/forgot_password", data),
  resetPasswordCheck: (data) => post("/v1/me/reset_password_check", data),
  resetPassword: (data) => post("/v1/me/reset_password", data),
  verify: (data) => post("/v1/auth/verify", data),
  resendVerification: (data) => post("/v1/auth/resend_verification", data),

  upsertDatabag: (data) => post("/v1/me/databags", data),

  getCurrentCustomer: (data) => get("/v1/me", data),
  updateCurrentCustomer: (data) => post("/v1/me/update", data),
  verifyCustomer: (data) => post("/v1/me/id_verify", data),
  updatePassword: (data) => post("/v1/me/password", data),
  closeAccount: (data) => post("/v1/me/close_account", data),

  getDashboard: (data) => get("/v1/me/dashboard2", data),
  getRentalVerficationsOfEnrollmentCase: (data) =>
    get(`/v1/enrollment_cases/${data.id}/rental_verifications`, data),
  authWithLink: (data) => post(`/v1/auth/via_link/`, data),

  getPaymentInstruments: (data) => get("/v1/payment_instruments", data),
  getBankAccounts: (data) => get("/v1/bank_accounts", data),
  newPlaidLinkToken: (data) => post("/v1/plaid/tokens/link", data),
  newPlaidLinkToken2: (data) => post("/v1/plaid/tokens/link2", data),
  updatePlaidLinkToken: (data) => post("/v1/plaid/tokens/update", data),
  plaidLinkTokenUpdated: (data) => post("/v1/plaid/tokens/updated", data),
  exchangePlaidPublicToken: (data) => post("/v1/plaid/tokens/exchange", data),
  addStripeCardFromToken: (data) =>
    post("/v1/stripe/add_card_from_token", data),

  getCurrentDraftPayment: (data) => get("/v1/draft_payments/current", data),
  createDraftPayment: (data) => post("/v1/draft_payments", data),
  patchDraftPayment: (data) => patch(`/v1/draft_payments/${data.id}`, data),
  publishDraftPayment: (data) =>
    post(`/v1/draft_payments/${data.id}/publish`, data),

  patchPayment: (data) => patch(`/v1/payments/${data.id}`, data),
  cancelPayment: (data) => post(`/v1/payments/${data.id}/cancel`, data),

  cancelPaymentSeries: (data) =>
    post(`/v1/payment_series/${data.id}/cancel`, data),

  getCustomerDetails: (data) => get("/v1/me/customer_details", data),
  getCustomerSettings: (data) => get("/v1/me/settings", data),
  patchCustomerSettings: (data) => patch(`/v1/me/settings`, data),

  getActivity: (data) => get("/v1/activity_events", data),
  getArrivalEstimates: (data) => get("/v1/arrival_estimates", data),
  getArrivalEstimatesMatrix: (data) =>
    get("/v1/arrival_estimates/matrix", data),

  getFees: (data) => get("/v1/fees", data),

  createJumpAuthCode: (data) => post("/v1/oauth/jumpauth/preauthorize", data),

  getPortals: (data) => get("/v1/portals/supported", data),
  checkPortalCredentials: (data) => post(`/v1/portals/${data.id}`, data),
  saveUnlistedPortalCredentials: (data) => post(`/v1/portals/unlisted`, data),

  getEnrollmentCases: (data) => get("/v1/enrollment_cases", data),
  createEnrollmentCase: (data) => post("/v1/enrollment_cases", data),
  setEnrollmentCaseAddress: (data) =>
    patch(`/v1/enrollment_cases/${data.id}/address`, data),
  updateEnrollmentCase: (data) =>
    patch(`/v1/enrollment_cases/${data.id}`, data),
  setEnrollmentCasePortal: (data) =>
    patch(`/v1/enrollment_cases/${data.id}/portal`, data),
  setEnrollmentCasePayee: (data) =>
    patch(`/v1/enrollment_cases/${data.id}/payee`, data),
  addEnrollmentCaseDocuments: (data) =>
    post(`/v1/enrollment_cases/${data.id}/add_documents`, data),
  updateEndOfLease: (data) =>
    patch(`/v1/enrollment_cases/${data.id}/end_lease_options`, data),
  confirmMoveOut: (data) =>
    post(`/v1/enrollment_cases/${data.id}/confirm_moved_out`, data),
  subscribeReportEnrollment: (data) =>
    post(
      `/v1/enrollment_cases/${data.id}/reporting_account_enrollment/subscribe`,
      data
    ),
  subscribePartnerCustomer: (data) =>
    post(
      `v1/enrollment_cases/${data.id}/reporting_account_enrollment/enroll_with_partner`,
      data
    ),
  subscribeSplitEnrollment: (data) =>
    post(
      `/v1/enrollment_cases/${data.id}/credit_account_enrollment/waitlist`,
      data
    ),
  addSubscriptionToEnrollment: (data) =>
    post(
      `/v1/enrollment_cases/${data.id}/reporting_account_enrollment/modify_subscription`,
      data
    ),
  createPresignedUrlLeaseUpload: (data) =>
    post(`/v1/documents/presign_put_url?filename=${data.filename}`),

  generateAuthToken: (data) =>
    post(`/v1/array_credit_score/generate_auth_token`, data),

  adminImpersonate: (data) =>
    post(`/admin/v1/auth/impersonate/${data.customerId}`, data),
  adminUnimpersonate: (data) => del(`/admin/v1/auth/impersonate`, data),
  createPotentialReferral: (data) => post(`/v1/potential_referrals`, data),

  getCheckoutOptions: (data) => get("/v1/orders/checkout_options", data),
  checkout: (data) => post(`/v1/orders/checkout`, data),

  sendBankLinkUrl: (data) =>
    post("/v1/bank_accounts/send_plaid_connect_url", data),

  closeAccount: (data) => post("/v1/me/close_account", data),
};

export default { ...apiBase, ...api, refreshInstance };
