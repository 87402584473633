import React from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../providers/auth";
import { InputField } from "../../components/FormFields/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useError } from "../../providers/useError";
import { Title } from "src/shared/Title";
import { Button } from "src/shared/Button";
import { schema } from "./schema";
import { Customer } from "src/providers/auth/types";

export const AuthPasswordView = () => {
  const { setPassword, refreshAuth } = useAuth();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const { setError } = useError();

  return (
    <div>
      <Title
        type="h1"
        style={{
          textAlign: "center",
        }}
      >
        Create password
      </Title>

      <Title
        type="h3"
        style={{
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        Make sure it has at least 8 characters
      </Title>

      <form
        onSubmit={handleSubmit(
          (data: { password: string }) => {
            return (
              setPassword(data)
                // @ts-ignore
                .then((d: Customer) => refreshAuth(d))
                .catch((e) => {
                  setError(e);
                })
            );
          },
          (e) => {
            console.log(e);
          }
        )}
      >
        <InputField
          name="password"
          label="Password"
          inputProps={{
            type: "password",
          }}
          fullWidth
          control={control}
        />

        <Button variant="primary" fullWidth type="submit">
          Create password
        </Button>
      </form>
    </div>
  );
};
