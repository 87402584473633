import { FC, ReactNode } from "react";
import styled from "@emotion/styled";
import { useLayout } from "src/components/layout/layout";

// Todo: make it gray
const BaseBadge = styled.div`
  /* Badge */
  display: block;
  box-sizing: border-box;
  border: 1px solid;
  padding: 5px 16px;
  border-radius: 32px;

  /* Text */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;

  /* Colors */
  color: #99a3ba;
  border-color: #f5f6f8;
  background-color: #f5f6f8;
`;

const PrimaryBadge = styled(BaseBadge)`
  color: #fff;
  border-color: ${(props: {
    theme: {
      colors: {
        primary: string;
      };
    };
  }) => props?.theme?.colors?.primary};
  background-color: ${(props) => props?.theme?.colors?.primary};
`;

const SuccessBadge = styled(BaseBadge)`
  color: #3dbd61;
  border-color: ${(props: { pTheme: "dark" | "light" }) =>
    props.pTheme === "dark" ? "#3dbd61" : "#ECF8EF"};
  background-color: ${(props) =>
    props.pTheme === "dark" ? props.color : "#ECF8EF"};
`;

const PendingBadge = styled(BaseBadge)`
  color: #1507ff;
  border-color: ${(props: { pTheme: "dark" | "light" }) =>
    props.pTheme === "dark" ? "#1507FF" : "#ECEBFF"};
  background-color: ${(props) =>
    props.pTheme === "dark" ? props.color : "#ECEBFF"};
`;

const ErrorBadge = styled(BaseBadge)`
  color: #f44336;
  border-color: ${(props: { pTheme: "dark" | "light" }) =>
    props.pTheme === "dark" ? "#f44336" : "#FDEAEA"};
  background-color: ${(props) =>
    props.pTheme === "dark" ? props.color : "#FDEAEA"};
`;

const WarningBadge = styled(BaseBadge)`
  color: #f5a623;
  border-color: ${(props: { pTheme: "dark" | "light" }) =>
    props.pTheme === "dark" ? "#BC9715" : "#F8F5E8"};
  background-color: ${(props) =>
    props.pTheme === "dark" ? props.color : "#F8F5E8"};
`;

export const Badge: FC<{
  color?: "success" | "pending" | "error" | "warning" | "primary";
  children?: string | ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}> = ({ color, children, ...rest }) => {
  const { secondaryColor, theme } = useLayout();

  if (color === "primary") {
    return (
      //@ts-ignore
      <PrimaryBadge {...rest} color={secondaryColor}>
        {children}
      </PrimaryBadge>
    );
  }

  if (color === "success")
    return (
      <SuccessBadge
        {...rest}
        color={secondaryColor}
        pTheme={theme as "light" | "dark"}
      >
        {children}
      </SuccessBadge>
    );

  if (color === "pending")
    return (
      <PendingBadge
        {...rest}
        color={secondaryColor}
        pTheme={theme as "light" | "dark"}
      >
        {children}
      </PendingBadge>
    );

  if (color === "error")
    return (
      <ErrorBadge
        {...rest}
        color={secondaryColor}
        pTheme={theme as "light" | "dark"}
      >
        {children}
      </ErrorBadge>
    );

  if (color === "warning")
    return (
      <WarningBadge
        {...rest}
        color={secondaryColor}
        pTheme={theme as "light" | "dark"}
      >
        {children}
      </WarningBadge>
    );

  return (
    <BaseBadge {...rest} color={secondaryColor}>
      {children}
    </BaseBadge>
  );
};
