import React from "react";
import logo from "./boomIcon.svg";

export default function BoomIcon(props) {
  return (
    <img
      src={logo}
      alt="Boom"
      style={props.style}
      className={props.className}
    />
  );
}
