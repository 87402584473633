// untitledui-js/icons doesn't have sufficient tree-shaking.
// So this component is subject to change

import styled from "@emotion/styled";
import { FC } from "react";
import { ETheme } from "src/components/layout/layout";
import { useTheme } from "@emotion/react";
import { Icon as SIcon } from "src/shared/Icon";

type UntitledUIIcon = any;

const StyledPrimaryButton = styled.button`
  border: 1px solid
    ${(props: { theme?: ETheme }) => props.theme?.colors?.border};
  outline: none;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  background: ${(props: { theme?: ETheme }) =>
    props.theme?.colors?.cardBackground};
`;

export const IconButton: FC<{
  Icon: UntitledUIIcon;
  onClick?: () => void;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
  className?: string;
  variant?: "primary" | "secondary";
}> = ({
  Icon,
  onClick,
  size = 24,
  color = "black",
  style,
  className,
  variant,
  ...props
}) => {
  const theme = useTheme() as ETheme;
  if (variant === "primary") {
    return (
      <StyledPrimaryButton
        className={className}
        style={{ ...style }}
        onClick={onClick}
        {...props}
      >
        <SIcon Icon={Icon} size={20} />
      </StyledPrimaryButton>
    );
  }

  return (
    <button
      className={className}
      style={{
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        cursor: "pointer",
        padding: 0,
        ...style,
      }}
      onClick={onClick}
      {...props}
    >
      <SIcon Icon={Icon} size={size} />
    </button>
  );
};
