import { string, object } from "yup";

export const schema = object().shape({
  firstName: string()
    .test("len", "Must be less than 20 characters", (val) => {
      if (val === undefined) {
        return true;
      }
      return val.length < 20;
    })
    .required("First name is required"),
  lastName: string()
    .test("len", "Must be less than 25 characters", (val) => {
      if (val === undefined) {
        return true;
      }
      return val.length < 25;
    })
    .required("Last name is required"),
  phone: string()
    .test("len", "Must be a 10-digit phone number", (val) => {
      if (val === undefined) {
        return true;
      }
      const length = val.replace(/-|_/g, "").length;
      return length === 13;
    })
    .required("Phone is required"),
  email: string()
    .matches(/\.[a-zA-Z]{2,5}$/, "Email domain is invalid")
    .test("con-domain", "Did you mean .com?", (v) => v && !v.endsWith(".con"))
    .email("Email is not valid")
    .required("Email is required"),
});
