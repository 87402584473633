import { useAuth } from "src/providers/auth";

export const DevManualLogout = () => {
  const { logOut } = useAuth() as { logOut: () => void };

  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <div>
      <h1>DevManualLogout</h1>

      <p>Press here to drop session</p>
      <button onClick={logOut} type="button">
        Logout
      </button>
    </div>
  );
};
