import React, { useState } from "react";

import { Popover } from "react-tiny-popover";
import { IconButton } from "src/shared/IconButton";
import { Maps, Users } from "untitledui-js";
import { useAuth } from "../auth";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
import { useIntercom } from "react-use-intercom";
import { Card } from "src/shared/Card";
import { useTheme } from "@emotion/react";
import { ETheme } from "src/components/layout/layout";
import { Icon } from "src/shared/Icon";

export const UserMenu = () => {
  const { show } = useIntercom();
  const theme = useTheme() as ETheme;

  //   const { theme } = useLayout();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { customer } = useAuth();

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["bottom"]} // preferred positions by priority
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
      content={
        <Card
          style={{
            zIndex: 3,
            padding: "16px 0",
            marginRight: 10,
            borderRadius: 8,
            width: 240,
          }}
        >
          <div
            style={{
              borderBottom: "1px solid",
              borderColor: theme?.colors?.border,
              padding: "0 24px",
              paddingBottom: 16,
            }}
          >
            <Title
              type="h2"
              style={{
                marginBottom: 8,
                maxWidth: 200,
                // ellipsis
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {customer?.firstName} {customer?.lastName}
            </Title>

            <Text
              type="sm"
              style={{
                maxWidth: 200,
                // ellipsis
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {customer?.email}
            </Text>
          </div>

          <div>
            <Title
              type="h2"
              onClick={() => {
                show();
                setIsPopoverOpen(false);
              }}
              style={{
                marginBottom: 8,
                display: "flex",
                gap: 12,
                alignItems: "center",
                padding: "0 24px",
                paddingTop: 16,
                cursor: "pointer",
              }}
            >
              <Icon Icon={Maps.Compass03} />
              Help & Support
            </Title>
          </div>
        </Card>
      }
    >
      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        <IconButton Icon={Users.User01} size={24} />
      </div>
    </Popover>
  );
};
