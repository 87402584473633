import React from "react";

/**
 * Use this component instead of a normal page when you run into an unhandled error.
 * Sort of like a 500 page.
 *
 * @param errorMessage
 * @param retryText
 * @param activityText
 * @param RootComponent
 */
export default function UnhandledError({
  errorMessage,
  retryText,
  activityText,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: 20,
      }}
    >
      <h1 color="primary">
        Sorry, that wasn&rsquo;t supposed to happen!&nbsp;
      </h1>
      <p>{activityText}</p>
      <p>
        It may be a temporary problem, or it may be an issue with Boom, but our
        team is already on it.&nbsp;
      </p>
      <p>
        {retryText}, and if you see this page again, feel free to reach out to
        us. Here's what we know about the error:&nbsp;
      </p>
      <pre>{errorMessage} </pre>

      <p>Your data is encrypted and stored with bank-level security.</p>
    </div>
  );
}
