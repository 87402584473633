import { useTheme } from "@emotion/react";
import { FC, createElement } from "react";
import { ETheme } from "src/components/layout/layout";

export const Icon: FC<{
  Icon: any;
  onClick?: () => void;
  size?: number;
  color?:
    | string
    | "text"
    | "subText"
    | "primary"
    | "secondary"
    | "danger"
    | "success"
    | "warning"
    | "info";
  style?: React.CSSProperties;
  className?: string;
}> = ({ Icon, onClick, size = 24, color = "text", ...props }) => {
  const theme = useTheme() as ETheme;

  const strokeColor = [
    "text",
    "subText",
    "primary",
    "secondary",
    "danger",
    "success",
    "warning",
    "info",
  ].includes(color)
    ? theme.colors[color]
    : color;
  return <Icon stroke={strokeColor} size={size} {...props} />;
};
