import { useEffect } from "react";
import { Button } from "src/shared/Button";
import { Title } from "src/shared/Title";
import { General } from "untitledui-js";

export const SuccessSlice = ({ redirectUrl }) => {
  useEffect(() => {
    if (redirectUrl) {
      document.location.href = redirectUrl;
    }
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: 24,
        gap: 24,
      }}
    >
      <General.Check
        size="24"
        stroke={"#fff"}
        style={{
          background: "#64C132",
          borderRadius: "50%",
          padding: 8,
        }}
      />

      <Title type="h1">You have successfully linked your bank account</Title>

      <div
        style={{
          flexGrow: 1,
        }}
      ></div>
      {redirectUrl ? (
        <a
          href={redirectUrl}
          target="_blank"
          style={{
            display: "block",
            width: "100%",
          }}
        >
          <Button variant={"primary"} fullWidth onClick={() => {}}>
            Go back
          </Button>
        </a>
      ) : null}
    </div>
  );
};
