import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "src/shared/Text";
import { Title } from "src/shared/Title";

import { schema } from "./schema";
import PhoneField from "src/components/FormFields/PhoneField";
import { Button } from "src/shared/Button";
import { useAuth } from "src/providers/auth";
import { useError } from "src/providers/useError";
import { InputField } from "src/components/FormFields/InputField";

export const AuthLoginView = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const { setError } = useError();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      phone: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  function onSubmit(data) {
    return signIn({ phone: data?.phone, password: data.password })
      .then(() => {
        navigate("/");
      })
      .catch(setError);
  }

  return (
    <div>
      <Title
        type="h1"
        style={{
          textAlign: "center",
        }}
      >
        Welcome
      </Title>

      <Title
        type="h3"
        style={{
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        Log in to your account
      </Title>

      <form
        onSubmit={handleSubmit(onSubmit, (e) => {
          console.log(e);
        })}
      >
        <PhoneField name="phone" control={control} />
        <InputField
          name="password"
          label="Password"
          inputProps={{
            type: "password",
          }}
          fullWidth
          control={control}
        />

        <Button variant="primary" fullWidth type="submit">
          Login
        </Button>
      </form>

      <Text
        type="label"
        style={{
          textAlign: "center",
        }}
      >
        Don't have an account?{" "}
        <Button
          type="button"
          onClick={() => {
            navigate("/auth");
          }}
        >
          Create one!
        </Button>
      </Text>
    </div>
  );
};
