import { FC, useState } from "react";
import { BankAccount } from "../types";
import { Button } from "src/shared/Button";
import { Card } from "src/shared/Card";
import LaunchLink from "src/Plaid/Plaid";
import api from "src/api";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
import { Badge } from "src/shared/Badge";
import { AddBankModal } from "../components/AddBankModal";
import { useAuth } from "src/providers/auth";
import { Features } from "src/providers/auth/types";

export const Accounts: FC<{
  accounts: BankAccount[];
  fetchPaymentInstruments: () => Promise<void>;
}> = ({ accounts, fetchPaymentInstruments }) => {
  const { partner } = useAuth();
  const [open, setOpen] = useState(false);
  const [linkToken, setLinkToken] = useState("");
  const [bankLinkUrl, setBankLinkUrl] = useState("");

  function onSubmit() {
    function fetchLinkToken() {
      const tokenPromise = api.newPlaidLinkToken();
      tokenPromise.then(api.pickData).then((d) => setLinkToken(d.linkToken));
    }
    fetchLinkToken();
  }

  const grouppedByInstitution = accounts.reduce((acc, account) => {
    if (!acc[account.institution.name]) {
      acc[account.institution.name] = [];
    }
    acc[account.institution.name].push(account);
    return acc;
  }, {} as Record<string, BankAccount[]>);
  const setToken = (t) => {
    fetchPaymentInstruments();
    setLinkToken(t);
  };
  return (
    <div>
      <AddBankModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        link={bankLinkUrl}
      />
      {Object.entries(grouppedByInstitution).map(
        ([institutionName, account]) => (
          <Card
            key={institutionName}
            style={{
              padding: 20,
              marginBottom: 10,
            }}
          >
            <Title type="h2" style={{}}>
              {institutionName}
            </Title>

            {account.map((a) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text type="sm">{a.name}</Text>
                  <Text
                    type="label"
                    style={{
                      marginTop: 8,
                      marginBottom: 16,
                    }}
                  >
                    ****
                    {a.last4}
                  </Text>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <Badge color={a.active ? "success" : "error"}>
                      {a.active ? "Active" : "Inactive"}
                    </Badge>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginRight: "auto",
                    }}
                  >
                    <Badge
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {a.accountSubtype}
                    </Badge>
                  </div>
                </div>
              </div>
            ))}
          </Card>
        )
      )}

      <Button
        variant="primary"
        fullWidth
        style={{
          marginTop: 16,
        }}
        onClick={() => {
          if (partner.features.includes(Features.BYPASS_BANK_LINKING)) {
            api
              .sendBankLinkUrl()
              .then(api.pickData)
              .then((d) => {
                setBankLinkUrl(d.plaidConnectUrl);
                setOpen(true);
              });
          } else {
            onSubmit();
          }
        }}
      >
        Link new bank account
      </Button>
      {linkToken ? (
        <LaunchLink
          token={linkToken}
          setToken={setToken}
          settingsUpdate={(b) => {
            setToken("");
          }}
        />
      ) : null}
    </div>
  );
};
