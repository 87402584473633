import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../auth";
import { AuthState } from "../auth/types";
import { SmartLogo } from "src/shared/SmartLogo";
import { useLayout } from "src/components/layout/layout";
import { Arrow, General } from "untitledui-js";
import { IconButton } from "src/shared/IconButton";
import { useNavigate } from "react-router-dom";
import { UserMenu } from "./UserMenu";
import { postEvent } from "src/helpers/postEvent";

type UILayoutProps = {
  ignoredPaths: string[];
  children: JSX.Element;
};

const pathsWithLogo = [
  "/auth",
  "/auth/login",
  "/auth/verify",
  "/auth/set-password",
  "/auth/reset-password",
  "/home",
];

const ignoredPaths = ["/enrollment/intro", "/plaid-connect"];

export const UILayout: FC<UILayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState, token } = useAuth();
  const { secondaryColor } = useLayout();

  if (
    ignoredPaths.includes(location.pathname) ||
    authState === AuthState.LOADING ||
    authState === AuthState.INTEGRATION_ERROR
  )
    return <div className="content-container hideScrollbar">{children}</div>;

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <nav
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          //   Prevents input overflow
          zIndex: 2,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: secondaryColor,
            height: 48,
            padding: "0 24px",
          }}
        >
          {pathsWithLogo.includes(location.pathname) ? (
            <SmartLogo size={32} />
          ) : (
            <IconButton
              Icon={Arrow.ChevronLeft}
              size={24}
              onClick={() => {
                navigate(-1);
              }}
            />
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {authState === AuthState.UNAUTHED ? null : (
              <>
                {!location.state && authState === AuthState.FULL ? (
                  <IconButton
                    Icon={General.Settings02}
                    size={24}
                    onClick={() => {
                      navigate("/settings");
                    }}
                  />
                ) : (
                  <UserMenu />
                )}
              </>
            )}
            {token ? (
              <IconButton
                Icon={General.XClose}
                size={24}
                style={{
                  marginLeft: 8,
                }}
                onClick={() => {
                  postEvent({
                    type: "action",
                    action: "close",
                    source: location.pathname,
                  });
                }}
              />
            ) : null}
          </div>
        </div>
      </nav>
      <div
        className="content-container"
        style={{
          position: "relative",
          height: "calc(100vh - 64px)",
          paddingTop: 48 + 16,
        }}
      >
        {children}
      </div>
    </div>
  );
};
