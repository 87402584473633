import { FC } from "react";
import { TailSpin } from "react-loader-spinner";

export const LoadingSlice: FC<{
  primaryColor: string;
}> = ({ primaryColor }) => (
  <div
    style={{
      position: "relative",
    }}
  >
    <TailSpin
      height="50"
      width="50"
      color={primaryColor}
      ariaLabel="tail-spin-loading"
      wrapperClass="animate-appear"
      wrapperStyle={{
        position: "absolute",
        left: "50%",
        top: "200px",
        transform: "translate(-50%, -50%)",
      }}
      visible={true}
    />
  </div>
);
