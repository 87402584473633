import React, { Suspense, lazy } from "react";
// import Auth from "src/components/Auth/Auth";
import { useAuth } from "src/providers/auth";
import { Routes, Route, Navigate } from "react-router-dom";

import { Home } from "src/views/home";

import { HomeIdView } from "src/views/home-id";
import { SettingsView } from "src/views/settings";
import { SettingsManageSubscriptionsView } from "src/views/settings-manage-subscriptions";
import { SettingsLinkedAccountsView } from "src/views/settings-linked-accounts";
import { DevManualLogout } from "src/views/_dev-manual-logout";
import { AuthState } from "../auth/types";
import { AuthView } from "src/views/auth";
import { AuthLoginView } from "src/views/auth-login";
import { AuthPasswordView } from "src/views/auth-password";
import { AuthVerifyView } from "src/views/auth-verify";

import { AuthForgotPasswordView } from "src/views/auth-forgot-password";
import { PlaidConnectView } from "src/views/plaid-connect-view";
import { NoMatch } from "./NoMatch";
import { ProtectedRoute } from "./ProtectedRoute";
import { IntegrationErrorView } from "src/views/integration-error";
import { SettingsDeleteAccountView } from "src/views/settings-delete-account";
import { DeleteAccountFallback } from "src/views/settings-delete-account/slices/Fallback";

const Enrollment = lazy(() => import("src/components/Enrollment/Enrollment"));

export const AppRouter = () => {
  const { authState } = useAuth();

  if (authState === AuthState.LOADING) return <NoMatch />;

  if (authState === AuthState.INTEGRATION_ERROR)
    return <IntegrationErrorView />;

  return (
    <Routes>
      <Route path="/plaid-connect" element={<PlaidConnectView />} />
      <Route
        path="/auth"
        element={
          <ProtectedRoute requiredState={AuthState.UNAUTHED}>
            <AuthView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/auth/login"
        element={
          <ProtectedRoute requiredState={AuthState.UNAUTHED}>
            <AuthLoginView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/auth/forgot-password"
        element={
          <ProtectedRoute requiredState={AuthState.UNAUTHED}>
            <AuthForgotPasswordView />
          </ProtectedRoute>
        }
      />

      <Route
        path="/auth/verify"
        element={
          <ProtectedRoute requiredState={AuthState.PHONE_VERIFICATION_REQUIRED}>
            <AuthVerifyView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/auth/set-password"
        element={
          <ProtectedRoute requiredState={AuthState.PASSWORD_REQUIRED}>
            <AuthPasswordView />
          </ProtectedRoute>
        }
      />

      <Route
        path="/enrollment/*"
        element={
          <ProtectedRoute>
            <Suspense fallback={null}>
              <Enrollment />
            </Suspense>
          </ProtectedRoute>
        }
      />

      <Route
        path="/home"
        element={
          <ProtectedRoute requiredState={AuthState.FULL}>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/home/:id"
        element={
          <ProtectedRoute requiredState={AuthState.FULL}>
            <HomeIdView />
          </ProtectedRoute>
        }
      />

      <Route
        path="/settings"
        element={
          <ProtectedRoute requiredState={AuthState.FULL}>
            <SettingsView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/manage-subscriptions"
        element={
          <ProtectedRoute requiredState={AuthState.FULL}>
            <SettingsManageSubscriptionsView />
          </ProtectedRoute>
        }
      />

      <Route
        path="/settings/linked-accounts"
        element={
          <ProtectedRoute requiredState={AuthState.FULL}>
            <SettingsLinkedAccountsView />
          </ProtectedRoute>
        }
      />

      <Route
        path="/settings/delete-account"
        element={
          <ProtectedRoute
            requiredState={AuthState.FULL}
            fallback={<DeleteAccountFallback />}
          >
            <SettingsDeleteAccountView />
          </ProtectedRoute>
        }
      />

      <Route
        // NAVIGATE HERE TO DROP SESSION
        path="/_dev/manual-logout"
        element={<DevManualLogout />}
      />

      <Route path="/" element={<Navigate to="/home" />} />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};
