import { AnonLaunchLink } from "./AnonPlaidLink";

export const LoadingSlice = ({ token, onSuccess, onExit }) => {
  return (
    <div>
      {token ? (
        <AnonLaunchLink token={token} onSuccess={onSuccess} onExit={onExit} />
      ) : null}
    </div>
  );
};
