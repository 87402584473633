import axios from "axios";
import humps from "humps";

function create(apiHost, config) {
  const { headers, ...rest } = config || {};
  const instance = axios.create({
    baseURL: apiHost,
    headers,
    timeout: 60000,
    withCredentials: true,
    transformResponse: [
      ...axios.defaults.transformResponse,
      (data) => humps.camelizeKeys(data),
    ],
    transformRequest: [
      (data) => humps.decamelizeKeys(data),
      ...axios.defaults.transformRequest,
    ],
    ...rest,
  });
  return instance;
}

function handleStatus(status, cb) {
  return (error) => {
    if (error?.response?.data?.error?.status === status) {
      return cb(error);
    }
    throw error;
  };
}

function mergeParams(params, o) {
  const cased = humps.decamelizeKeys(params || {});
  return { params: cased, ...o };
}

export default {
  create,
  handleStatus,
  mergeParams,
  pick: (s) => (o) => o[s],
  pickData: (o) => o.data,
  swallow: (status) => handleStatus(status, () => undefined),
};
