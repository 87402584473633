const version = "0.0.0";
const buildTime = Date.now();

export const config = {
  apiHost: process.env.REACT_APP_API_HOST,
  remoteLoggingLevel: process.env.REACT_APP_LOG_LEVEL,
  environment: process.env.NODE_ENV,
  intercomId: process.env.REACT_APP_ID_INTERCOM,
  stripePublishableKey: process.env.REACT_APP_STRIPE,
  sentryDns: process.env.REACT_APP_SENTRY_DNS,
  appEnv: process.env.REACT_APP_ENV,
  amplitudeKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
  singular: {
    key: process.env.REACT_APP_SINGULAR_API_KEY,
    secret: process.env.REACT_APP_SINGULAR_API_SECRET,
  },
  partnerId:
    !process.env.REACT_APP_PARTNER_ID ||
    process.env.REACT_APP_PARTNER_ID === "-1"
      ? undefined
      : process.env.REACT_APP_PARTNER_ID,
  version,
  buildTime,
};
