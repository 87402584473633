import { Button } from "src/shared/Button";
import { Title } from "src/shared/Title";
import { General } from "untitledui-js";
import { Text } from "src/shared/Text";
import { FC } from "react";

export const ErrorSlice: FC<{
  redirectUrl: string;
  retry: () => void;
}> = ({ redirectUrl, retry }) => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: 24,
        gap: 24,
      }}
    >
      <General.XClose
        size="24"
        stroke={"#fff"}
        style={{
          background: "#FF5816",
          borderRadius: "50%",
          padding: 8,
        }}
      />

      <Title type="h1">Failed to link bank accounts</Title>

      <div>
        <Text type="sm">Please try again</Text>

        <Text type="sm">
          If you are unable to connect your bank account, unfortunately, you
          won't be able to use our reporting services. You can delete your Boom
          account in the settings.{" "}
          <a href={redirectUrl} target="_blank">
            <Button type="button">Go back to settings</Button>
          </a>
        </Text>
      </div>

      <div
        style={{
          flexGrow: 1,
        }}
      ></div>

      <Button variant={"primary"} onClick={retry}>
        Try again
      </Button>
    </div>
  );
};
