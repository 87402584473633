import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "src/shared/Text";
import { Title } from "src/shared/Title";

// import { schema } from "./schema";
import PhoneField from "src/components/FormFields/PhoneField";
import { Button } from "src/shared/Button";
import { useAuth } from "src/providers/auth";
import { useError } from "src/providers/useError";
import { InputField } from "src/components/FormFields/InputField";

import api from "../../api";
import OtpInput from "react-otp-input";
import { resetSchema, schema } from "./schema";

export const AuthForgotPasswordView = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const { setError } = useError();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      phone: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  function onSubmit(data) {
    return signIn({ phone: data?.phone, password: data.password })
      .then(() => {
        navigate("/");
      })
      .catch(setError);
  }

  return (
    <div>
      <Title
        type="h1"
        style={{
          textAlign: "center",
        }}
      >
        Welcome
      </Title>

      <Title
        type="h3"
        style={{
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        Log in to your account
      </Title>

      <form
        onSubmit={handleSubmit(onSubmit, (e) => {
          console.log(e);
        })}
      >
        <PhoneField name="phone" control={control} />
        <InputField
          name="password"
          label="Password"
          inputProps={{
            type: "password",
          }}
          fullWidth
          control={control}
        />

        <Button variant="primary" fullWidth type="submit">
          Login
        </Button>
      </form>

      <Text
        type="label"
        style={{
          textAlign: "center",
        }}
      >
        Don't have an account?{" "}
        <Button
          type="button"
          onClick={() => {
            navigate("/auth");
          }}
        >
          Create one!
        </Button>
      </Text>
    </div>
  );
};

export default function ForgotPassword(props) {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  const { setError } = useError();
  const [stage, setStage] = React.useState("enter-phone");
  const [code, setCode] = React.useState("");
  const [phone, setPhone] = React.useState("");

  function onSubmit({ phone }) {
    setError("");
    setCode("");
    api
      .forgotPassword({ phone })
      .then(() => {
        setPhone(phone);
        setStage("enter-code");
        setError("");
      })
      .catch(setError);
  }

  if (stage === "enter-phone") {
    return <PhoneField name="phone" control={control} />;
  } else if (stage === "enter-code") {
    return (
      <VerifyCode
        phone={phone}
        resendCode={onSubmit}
        code={code}
        setCode={setCode}
        setStage={setStage}
      />
    );
  } else {
    return (
      <ResetPassword
        resetPassword={resetPassword}
        code={code}
        navigate={navigate}
      />
    );
  }
}

function VerifyCode({ phone, code, setCode, setStage, resendCode }) {
  const { setError } = useError();
  function onCodeEnter(num) {
    api
      .resetPasswordCheck({ token: num })
      .then(api.pickData)
      .then(({ valid }) => {
        if (!valid) {
          return Promise.reject("Sorry, that code is not valid.");
        } else {
          setStage("reset-password");
          setError("");
          setCode(num);
        }
      })
      .catch(setError);
  }

  return (
    <div>
      <div>
        <OtpInput
          value={code}
          onChange={(num) => {
            setCode(num);
            if (num.length === 6) {
              onCodeEnter(num);
            }
          }}
          numInputs={6}
          //   inputStyle={classes.otpInput}
          //   focusStyle={classes.otpInputFocused}
          containerStyle={{ marginBottom: 32 }}
        />
      </div>
      <div>
        <Button
          //   className={classes.freeButton}
          onClick={() => resendCode({ phone })}
        >
          Resend the code
        </Button>
      </div>
    </div>
  );
}

function ResetPassword({ resetPassword, code, navigate }) {
  const { setError } = useError();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(resetSchema),
  });
  const onSubmit = ({ password }) => {
    resetPassword({ token: code, password })
      .then(() => navigate("/auth"))
      .catch(setError);
  };
  return (
    <div
    //   title="Create new password"
    //   submit={handleSubmit(onSubmit)}
    //   buttonTitle="Set new password"
    >
      <InputField type="password" control={control} />
    </div>
  );
}
