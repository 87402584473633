export type EmittedEvent = AnalyticsEvent | ActionEvent;

// PageIdentifier -- page name
// PageIdentifier gonna be registered as enum later
type PageIdentifier = string;

type AnalyticsEvent = {
  type: "analytics";
  action: "pageview" | "click" | "";
  source: PageIdentifier;
  message?: string;
};

type ActionEvent = {
  type: "action";
  action: "close" | "auth-error";
  source?: PageIdentifier;
  message?: string;
};

export const postEvent = async (event: EmittedEvent) => {
  try {
    const stringifiedEvent = JSON.stringify(event);
    window?.postMessage(stringifiedEvent, "*");
    window?.parent.postMessage(stringifiedEvent, "*");
  } catch (err) {
    console.error(err);
  }
};
