import { object, string } from "yup";

export const schema = object().shape({
  phone: string()
    .test("len", "Must be a 10-digit phone number", (val) => {
      if (val === undefined) {
        return true;
      }
      const length = val.replace(/-|_/g, "").length;
      return length === 13;
    })
    .required("Phone is required"),
});

export const resetSchema = object().shape({
  password: string()
    .min(8, "Passwords must be at least 8 characters")
    .required("Password is required"),
});
