import { FC } from "react";
import { Card } from "src/shared/Card";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
// import { CopyInput } from "src/shared/CopyInput";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import { useTheme } from "@emotion/react";
import { ETheme } from "src/components/layout/layout";
import { CopyInput } from "src/shared/CopyInput";
import api from "src/api";

export const AddBankModal: FC<{
  open: boolean;
  onClose: () => void;
  link: string;
}> = ({ open, onClose, link }) => {
  const theme = useTheme() as ETheme;
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{
        modal: {
          padding: 0,
          borderRadius: 8,
          maxWidth: 374,
          boxShadow: "0px 4px 31px 0px #060606",
          background: theme.colors.cardBackground,
        },
        overlay: {
          background: "#000000BA",
        },
        closeButton: {
          display: "none",
        },
      }}
    >
      <Card
        style={{
          maxWidth: 374,
          padding: 24,
        }}
      >
        <Title type="h1">
          We've sent the secure link with instructions to your email
        </Title>

        <Text type="label">
          Use the secure link we sent to your email, or copy the link below and
          paste it into your browser
        </Text>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            marginTop: 24,
          }}
        >
          <CopyInput
            style={{
              width: "100%",
            }}
            onClick={() => {
              api
                .sendBankLinkUrl()
                .then(() => {
                  toast.success("Link successfully sent.", {
                    autoClose: 1500,
                    toastId: "bank-link-resent",
                  });
                })
                .catch((e) => {
                  toast.error("Failed to send link. Please try again later.", {
                    autoClose: 1500,
                    toastId: "bank-link-resent-failed",
                  });
                });
            }}
          >
            <Text
              type="label"
              style={{
                // ellipiss
                overflow: "hidden",
                textAlign: "center",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
              color="text"
            >
              Resend Link
            </Text>
          </CopyInput>

          <CopyInput
            style={{
              width: "100%",
            }}
            onClick={() => {
              toast.clearWaitingQueue();
              toast.promise(navigator.clipboard.writeText(link), {
                pending: "Copying link to clipboard...",
                success: "Link copied to clipboard.",
                error: "Something went wrong. Please try again.",
              });
            }}
          >
            <Text
              type="label"
              style={{
                // ellipiss
                overflow: "hidden",
                textAlign: "center",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
              color="text"
            >
              Copy Link
            </Text>
          </CopyInput>
        </div>
      </Card>
    </Modal>
  );
};
