import styled from "@emotion/styled";
import { ETheme } from "src/components/layout/layout";

const StyledEl = styled.div`
  box-sizing: border-box;
  height: 40px;
  display: flex;
  max-width: calc(100% - 48px);
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${(props: { theme?: ETheme }) => props.theme?.colors.border};
  background-color: #fff;
  /* flex-shrink: initial; */
  cursor: pointer;
`;

export const CopyInput = ({ children, ...props }) => {
  return <StyledEl {...props}>{children}</StyledEl>;
};
