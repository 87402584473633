import React from "react";
import { useController } from "react-hook-form";
import InputMask from "react-input-mask";
import { StyledInput, StyledErrorMessage } from "./InputField";

export default function PhoneField(props) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <>
      <InputMask mask="(999) 999-9999" {...field}>
        {(inputProps) => (
          <StyledInput
            {...inputProps}
            error={error?.message}
            label={props.label || "Phone number"}
            placeholder={props.label || "Phone number"}
          />
        )}
      </InputMask>
      {error?.message ? (
        <StyledErrorMessage type="sm">{error.message}</StyledErrorMessage>
      ) : null}
    </>
  );
}
