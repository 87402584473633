export enum AuthState {
  LOADING = "LOADING",

  FULL = "FULL", // can access homescreen

  PHONE_VERIFICATION_REQUIRED = "PHONE_VERIFICATION_REQUIRED", // former status - unverified
  PASSWORD_REQUIRED = "PASSWORD_REQUIRED", // former status - onboarding
  ONBOARDING_REQUIRED = "ONBOARDING_REQUIRED", // former status - enrolling

  // Will see if I need these.
  INTEGRATION_ERROR = `integration_error`,

  UNAUTHED = `UNAUTHED`,
}

export enum Features {
  API_ACCESS = "api_access",
  BOOM_SCREEN = "boom_screen",
  BYPASS_BANK_LINKING = "bypass_bank_linking",
  DELINQUENCY_REPORTING = "delinquency_reporting",
  FREE_CHECKOUT = "free_checkout",
  FREE_ONGOING = "free_ongoing",
}

export type Partner = {
  name: string;
  features: Features[];
};

export type Customer = {
  id: number;
  phoneVerified: boolean;
  onboarded: boolean;
  arrayClientKey: null | unknown;
  appliedForEnrollment: boolean;
  emailVerified: boolean;
  partner?: Partner;
  firstName?: string;
  lastName?: string;
  name?: string; // full name `${firstName} ${lastName}`
  email?: string;
  phone?: string; //unformatted phone number
  usPhone?: string; // formatted phone number
  createdAt?: string;
  partnerServiceType?: string;
};

export type Context = {
  authState: AuthState;
  token?: string;
  customer?: Customer;
  partner?: Customer["partner"];
  error?: string;
  logOut: () => Promise<void>;
  signIn: (data: { phone: string; password: string }) => Promise<void>;
  signUp: (data: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
  }) => Promise<void>;
  verify: (data: { token: string }) => Promise<any>;
  resetPassword: (data: { email: string }) => Promise<void>;
  setPassword: (data: { password: string }) => Promise<void>;
  refreshAuth: (data?: Customer) => Promise<void>;
};
