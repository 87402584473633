// TODO: TEST THIS

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import api from "src/api";
import { useNavigate } from "react-router-dom";
import { useError } from "src/providers/useError";
import { Title } from "src/shared/Title";
import { Button } from "src/shared/Button";
import { useLayout } from "src/components/layout/layout";
import { useAuth } from "src/providers/auth";
import { Customer } from "src/providers/auth/types";

export const AuthVerifyView = () => {
  const { primaryColor } = useLayout();
  const { handleSubmit } = useForm();

  const [code, setCode] = React.useState("");

  const { customer, verify, refreshAuth } = useAuth();
  const { setError } = useError();

  useEffect(() => {
    api.resendVerification({ transport: "sms" });
  }, []);

  function onSubmit(num) {
    return (
      verify({ token: num })
        // @ts-ignore
        .then((d: { customer: Customer }) => {
          console.log(d);
          refreshAuth(d?.customer);
        })
        .catch(setError)
    );
  }

  return (
    <div>
      <Title
        type="h1"
        style={{
          textAlign: "center",
        }}
      >
        Verify your number
      </Title>

      <Title
        type="h3"
        style={{
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        Enter the 6-digit code we've sent to {customer?.phone}
      </Title>

      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <OtpInput
            value={code}
            onChange={(num) => {
              setCode(num);
              if (num.length === 6) {
                onSubmit(num);
              }
            }}
            numInputs={6}
            inputStyle={{
              display: "block!important",
              fontSize: "20px",
              color: "#000",
              padding: "12px",
              borderRadius: 8,
              border: "1px solid",
              borderColor: "#e8e8e8",
            }}
            focusStyle={{
              outline: "none",
              border: "1px solid",
              borderColor: primaryColor,
            }}
            containerStyle={{
              marginBottom: 32,
              display: "flex",
              justifyContent: "space-between",
              gap: 8,
            }}
          />
        </div>

        <Button
          type="button"
          style={{
            margin: "0 auto",
            display: "block",
          }}
          onClick={() => {
            setError("");
            setCode("");
            api.resendVerification({ transport: "sms" });
          }}
        >
          Resend the code
        </Button>
      </form>
    </div>
  );
};
