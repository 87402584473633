import { Title } from "src/shared/Title";
import { Alerts } from "untitledui-js";
import { useTheme } from "@emotion/react";
import { ETheme } from "src/components/layout/layout";
import { useAuth } from "src/providers/auth";
import { useEffect } from "react";
import { postEvent } from "src/helpers/postEvent";

export const IntegrationErrorView = () => {
  const { error } = useAuth();

  useEffect(() => {
    postEvent({
      type: "analytics",
      action: "pageview",
      source: "/integration-error",
      message: error || "Unknown error",
    });
  }, []);

  const theme = useTheme() as ETheme;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <div
        style={{
          marginTop: "64px",
          transform: "rotate(180deg)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Alerts.AlertCircle size="64" stroke={theme?.colors?.danger} />
      </div>
      <Title
        type="h1"
        style={{
          textAlign: "center",
        }}
      >
        Integration error
      </Title>

      <Title
        type="h3"
        color="subText"
        style={{
          textAlign: "center",
        }}
      >
        {error ||
          "Oops, seems like something went wrong. Please try again later"}
      </Title>
    </div>
  );
};
