import { createContext, useContext, useEffect, useState } from "react";
import api from "../../api";
import { useIntercom } from "react-use-intercom";
import { Customer, AuthState, Context } from "./types";
import { postEvent } from "src/helpers/postEvent";

// Used to define successful auth state
// do not use this function if authWithLink or getCurrentCustomer failed
const getPermissionAuthState = ({
  customer,
  ignorePassword,
}: {
  customer: Customer;
  ignorePassword?: boolean;
}) => {
  const phoneVerified = customer.phoneVerified;
  const passwordVerified = customer.onboarded || ignorePassword;
  const enrolled = customer.appliedForEnrollment;

  if (phoneVerified && passwordVerified && enrolled) return AuthState.FULL;
  if (phoneVerified && passwordVerified) return AuthState.ONBOARDING_REQUIRED;
  if (phoneVerified) return AuthState.PASSWORD_REQUIRED;
  return AuthState.PHONE_VERIFICATION_REQUIRED;
};

const AuthContext = createContext<Context>({
  authState: AuthState.LOADING,
  logOut: () => Promise.resolve(),
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  setPassword: () => Promise.resolve(),
  refreshAuth: () => Promise.resolve(),
});

export function AuthProvider({ token, children }) {
  const { boot } = useIntercom();

  const [customer, setCustomer] = useState<Customer>();
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING);
  const [error, setError] = useState<string>();

  const partner = customer?.partner;

  const refreshAuth = (data?: unknown) => {
    setAuthState(AuthState.LOADING);
    return (
      token
        ? api.authWithLink({ token })
        : data
        ? Promise.resolve({ data })
        : api.getCurrentCustomer()
    )
      .then(api.pickData)
      .then((pCustomer: Customer) => {
        setCustomer(pCustomer);
        const aState = getPermissionAuthState({
          customer: pCustomer,
          ignorePassword: !!token,
        });

        setAuthState(aState);
        setError(undefined);

        boot({
          userId: `${pCustomer.id}`,
          customAttributes: {
            source: pCustomer.partner?.name || "web_enrollment",
          },
          hideDefaultLauncher: [
            AuthState.ONBOARDING_REQUIRED,
            AuthState.FULL,
          ].includes(authState),
        });
      })
      .catch((err) => {
        if (token) {
          const errorMessage =
            err?.response?.data?.error?.status === 401
              ? "Unable to authenticate user token"
              : "Oops, seems like something went wrong. Please try again later.";

          setAuthState(AuthState.INTEGRATION_ERROR);
          postEvent({
            type: "action",
            action: "auth-error",
            message: errorMessage,
          });
          setError(errorMessage);
        } else setAuthState(AuthState.UNAUTHED);
        setCustomer(undefined);
      });
  };

  useEffect(() => {
    refreshAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        customer,
        partner,
        authState,
        // gotoPostAuth,
        error,
        logOut: () =>
          api.signOut().then(() => {
            window?.localStorage?.removeItem("token");
            api.refreshInstance();
            return refreshAuth();
          }),
        token,
        signIn: (data) => api.signIn(data).then(refreshAuth),
        signUp: (data) => api.signUp(data).then(refreshAuth),
        verify: (data) => api.verify(data),
        resetPassword: (data) => api.resetPassword(data).then(refreshAuth),
        refreshAuth,
        setPassword: (data) =>
          api
            .updatePassword({
              currentPassword: "password",
              newPassword: data.password,
            })
            .then(api.pickData),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
