import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";

const NotLoggedInSteps = () => {
  return (
    <div>
      <Title type="h2">
        1. <a href="https://go.boompay.app">Download or open</a> and login to
        the Boom app
      </Title>

      <Title type="h2">2. Click the "user" icon at the to of the screen</Title>

      <Title type="h2">
        3. Click "Help & Support" and request to delete your account
      </Title>
    </div>
  );
};

export const DeleteAccountFallback = () => {
  return (
    <div>
      <Title
        type="h1"
        style={{
          marginBottom: 24,
        }}
      >
        Delete your Boom account
      </Title>

      <Text type="label">You can delete your Boom account at any time</Text>

      <Text
        type="label"
        style={{
          marginBottom: 24,
        }}
      >
        To delete your account please follow the steps below:
      </Text>

      <NotLoggedInSteps />
    </div>
  );
};
