import React from "react";
export const PrivacyPolicy = () => {
  return (
    <>
      <h1>Privacy Policy</h1>
      <p>Last Updated: September 1, 2022</p>
      <p>
        <br />
      </p>
      <p>Welcome to Boom.</p>
      <p>
        <br />
      </p>
      <p>
        At Boom, we plan to change people's relationship with their largest,
        most stressful spend category, housing. We plan to do so with
        transparency, trust and a best-in class organization, products and
        features.
      </p>
      <p>
        This document sets forth the Boom Privacy Policy for use of the Boom
        service ("Service") offered to you ("you") by Boom ("Boom", "we", "us",
        and "our")<span>. </span>This Privacy Policy describes how we treat your
        personal information. Please read it carefully before using the Service.
        We are committed to protecting your personal information and if you have
        any concerns about this notice or our practices, please get in touch via
        our chat functionality on the website or in-app<span>.</span>
      </p>
      <p>
        <br />
      </p>
      <p>What information do we collect about you and how do we use it?</p>
      <p>Types of Information We Collect</p>
      <p>
        Personal information means information that can be used to identify and
        contact you. Examples of personal information include your full name,
        address, IP address, and Social Security number. You do not need to
        submit personal information to access the Service generally. However,
        you may be required to submit personal information to access certain
        aspects of the Service.
      </p>
      <p>
        We collect personal information when you purchase services through the
        Service; create an account; subscribe to our newsletter; participate in
        registration forms, feedback forms or surveys; receive customer or
        technical support; or participate in components of the Service through
        which users may submit content.
      </p>
      <p>
        <br />
      </p>
      <p>
        To create an account through the Service, we require you to provide (i)
        if you are a renter, certain personal information about yourself (name,
        email address, password, phone number, date of birth, Social Security
        number, property address and/or prior property address), personal
        information of your landlord or property manager (name, phone number and
        address), rental information (rent amount and payment dates) and any
        information necessary for us to verify and validate the information with
        your landlord or property manager and (ii) if you are a landlord or
        property manager, your personal information and personal information of
        your tenants, and rental information. Some of the personal information
        we collect when you register, like your Social Security number, is
        required by the credit bureau to verify your identity. Your contact
        information will only be made available to other users as authorized by
        you in connection with the property address you submit.
      </p>
      <p>
        <br />
      </p>
      <p>
        When purchasing services through the Service, you will also likely be
        required to provide a credit card number, billing address, phone number
        or other payment information. No information of this type will be
        collected unless you have registered for aspects of the Service that
        require it.
      </p>
      <p>
        By submitting personal information through the Site, you authorize Boom
        to share this personal information for the purposes identified herein
        and on the Site, such as responding to user inquiries, processing
        transactions, distributing materials, and requesting feedback. In
        addition to the information that you provide to us, we automatically
        record other personal information such as your browser type and the date
        and time of Service access. This information may be aggregated to
        measure the number of visits, average time spent, page views, and other
        statistics about users of the Service. We also may use this data to
        monitor the Service's performance and to make the Service easier and
        more convenient to use. We will elaborate further on the details of
        automatic collection below.
      </p>
      <p>
        <br />
      </p>
      <h3>Third Party Privacy Policies</h3>
      <p>
        Through the Service, you may be able to link to technology, software and
        services owned and controlled by third parties ("Third Party Features").
        You may be permitted or required to submit personal information to
        access Third Party Features. Such use of Third Party Features and
        submission of information through Third Party Features will be subject
        to such applicable third party's terms of use, terms of service and
        privacy policy. You agree to look solely to the applicable third party
        and not to Boom to enforce your rights in relation thereto.
      </p>
      <p>
        When you have clicked on a third party logo or URL displayed on the
        Site, or accessible through the Service, which links you to any Third
        Party Features, our Privacy Policy and Terms of Use no longer applies
        and you must read the terms of use, terms of service and privacy policy
        of the third party to see how your personal information will be handled
        on their website.
      </p>
      <p>
        <br />
      </p>
      <h3>How We Use Your Personal Information</h3>
      <p>
        When you register for our Services, we use the personal information you
        provide to us to verify your identity, communicate with you, and provide
        the Service to you, depending on the aspects of the Service for which
        you register. This includes verifying your identity, property address,
        and rent amount; reporting your information to third party credit
        bureaus; obtaining your credit score and excerpts of your credit report
        before and after the initial reporting of your rental history to the
        credit bureaus; alerting you of new products, services, features, or
        enhancements to the Service; handling your customer service questions or
        issues; processing orders; requesting feedback; distributing
        newsletters; and administering contests, promotions or surveys. We may
        also use your email address and phone number as part of the Service in
        sending you messages about the Service and other general announcements.
        We keep track of your interactions with us, including but not limited to
        your activity within the Service.
      </p>
      <p>
        Except as set forth in this Privacy Policy, or unless we have your
        consent, we will not share your personal information with any person or
        entity other than those affiliated with us, entities acting on our
        behalf, and relevant third parties such as those needed to collect and
        maintain our servers and perform technology and related services.
      </p>
      <p>
        <br />
      </p>
      <p>
        We may supplement personal information we collect about you with
        information from third parties in order to enhance our ability to serve
        you. We may combine the information we receive from outside sources with
        personal information we collect through the Services. We treat all
        personal information about you, whether received from you or from a
        third party, in accordance with this Privacy Policy and our Terms of
        Use.
      </p>
      <p>
        We may use your personal information with other information that we have
        collected, whether through the Services or from third parties, to tailor
        our content to you and to offer you opportunities to consider financial
        products or services that we believe may be of interest to you, such as
        credit card offers. We may process your personal information in a manner
        such that the end-product does not personally identify you or any other
        user of the Service. We may make your personal information
        non-personally identifiable by either combining it with information
        about other users (aggregating your personal information with
        information about other users), or by removing characteristics (such as
        your name or email address) that make the information personally
        identifiable. This process is known as de-personalizing your information
        (the "De-Personalized Information"). You grant us a royalty-free,
        worldwide, perpetual, fully paid-up, sublicenseable, irrevocable,
        non-exclusive and fully transferable right and license to use your
        personal information in connection with the creation and development of
        De-Personalized Information and analytical and statistical analysis
        tools relating to the use of the customer data we collect in providing
        the Service (the "Analytical Data"). We are expressly authorized to make
        any commercial use of the De-Personalized Information and Analytical
        Data, including without limitation, sharing the De-Personalized
        Information and Analytical Data with third parties, provided that we do
        not sell, trade, or otherwise transfer from us any part of the
        information or data that personally identifies any Service users.
      </p>
      <p>
        <br />
      </p>
      <h3>Do Not Track</h3>
      <p>
        In compliance with California legislation, AB370, effective January 1,
        2014, Boom practices in responding to "do not track" signals and
        collecting user information over time and across a network of websites
        when you visit the Site are as follows: We honor the request of "do not
        track" signals sent by your browser
      </p>
      <p>
        when you visit the Site. Additionally, Boom does not collect user
        information about your online activities over time and across different
        websites, or authorize third parties to do so.
      </p>
      <p>
        <br />
      </p>
      <h3>Google Analytics</h3>
      <p>
        Boom uses Google Analytics to help us learn about who visits the Site
        and what pages are being viewed.
      </p>
      <p>
        <br />
      </p>
      <h3>Cookies</h3>
      <p>
        "Cookies" are small bits of electronic information that a website sends
        to a visitor's browser and are stored on the visitor's hard drive. Like
        many websites, we employ cookies in certain areas of the Site to allow
        us to provide information to make your online experience more
        convenient. For example, when you return to the Site after logging in,
        cookies provide information to the Site so that the Site will remember
        who you are on subsequent visits, speeding up or enhancing your
        experience of the Service by, for instance, tailoring content to you.
        Cookies may also be used to keep track of your login name and password.
        While Boom may use cookies to track your visit to the Site, this
        information does not identify you personally and you remain anonymous,
        even if you have previously submitted personal information to Boom
        through the Site or otherwise. You may still access the Service if you
        set your browser not to accept cookies.Third parties whose websites are
        accessible through links on the Site may also use their own cookies when
        you link to their site or service. Boom does not control these third
        parties' use of cookies and cannot guarantee that they will uphold the
        same privacy standards as Boom. Boom expressly disclaims any and all
        liability related to the collection, use or disclosure of information by
        third parties. You should review the privacy policies of any third party
        sites that you visit through a link from the Site
      </p>
      <p>
        to understand how these third parties use cookies and the information
        they collect through cookies. Most cookies are "session cookies" which
        means that they are automatically deleted at the end of each session.
        Most browsers are initially set to accept cookies. You can set your
        browser to notify you when you receive a cookie, giving you a chance to
        decide whether or not to accept it.
      </p>
      <p>
        <br />
      </p>
      <h3>Log Information</h3>
      <p>
        Our servers automatically record information that your browser sends
        whenever you visit the Site. This information includes your Internet
        Protocol address, your browser type and version, the search engine you
        used to find the Service, if any, which aspects of the Service you use
        and from where, and when and how long you use them. We use this
        information to monitor and analyze how users use the Service, to provide
        customer service and to maintain and improve the Service.
      </p>
      <p>
        We may also collect similar information from emails we may send to you
        which then help us track which emails are opened and which links are
        clicked by recipients. The information allows for more accurate
        reporting and improvement of the Service.
      </p>
      <p>
        <br />
      </p>
      <h3>Our Policy on Minors</h3>
      <p>
        We don't allow persons under 18 to register for the Service, nor do we
        knowingly collect any personally identifiable information from persons
        under the age of 18. We do not direct any of our business practices or
        system outputs towards persons under the age of 18. If we are ever
        notified or have any other reason to believe that we have collected
        information from or about anyone under the age of 18, we will promptly
        delete the information and any account associated with that information.
        We take children's privacy seriously, and encourage parents to play an
        active role in their children's online experience at all times.
      </p>
      <h2>Will we share your personal information with third parties?</h2>
      <p>
        First, we want to be clear about how we do not share information about
        you:
      </p>
      <ul id="l1">
        <li data-list-text="•">
          <p>
            We do not share your credit reports or scores with unaffiliated
            third parties except agents acting on our behalf. This means we may
            share information about you with service providers or agents in
            order to have them perform on our behalf any of the activities we
            are permitted to conduct under this Privacy Policy and our Terms of
            Service. This includes things like designing or operating features,
            helping us research and test feature improvements, identifying
            offers or personalized recommendations, performing analytics and
            marketing our Services. These third parties are acting on our behalf
            so while they may have access to your information, including things
            like your credit reports and scores, they're required to follow our
            instructions and meet the standards explained in our Privacy Policy
            when handling information about you that we provide them
          </p>
        </li>
        <li data-list-text="•">
          <p>
            We do not sell or rent Personal Information to unaffiliated third
            parties for their advertising or marketing lists.
          </p>
          <p>
            <br />
          </p>
          <h3>Sharing Your Information</h3>
          <p>
            We use your personal information to provide the Service to you,
            deliver products and services you request, complete transactions,
            handle your customer service questions or issues and send
            communications to you about promotions, updates, or special offers
            from Boom that may be of interest to you. The personal information
            uploaded by you through the Service will be shared only with
            authorized persons to whom you specifically grant access. For
            example, when you register for an account with us, we will ask for
            your consent to share your
          </p>
          <p>
            personal information with a third party credit bureau, your landlord
            or property manager (if you are a renter), or your tenant (if you
            are a landlord or property manager). We may also use your
            De-Personalized Information to provide analyses of our users in the
            aggregate (including Analytical Data subject to the terms of this
            Privacy Policy) to prospective partners and other third parties. We
            will also share the personal information we collect from you under
            the following circumstances, and you expressly authorize us to share
            your information as follows:
          </p>
          <p>
            <br />
          </p>
        </li>
        <li data-list-text="•">
          <p>
            Protection of rights.{" "}
            <span>
              We will share personal information if we have a good faith belief
              that (i) access, use, preservation or disclosure of such
              information is reasonably necessary to satisfy any applicable law,
              regulation, legal process, such as a court order or subpoena, or a
              request by law enforcement or governmental authorities, (ii) such
              action is appropriate to enforce our Terms of Use for the Service,
              including any investigation of potential violations thereof, (iii)
              such action is necessary to detect, prevent, or otherwise address
              fraud, security or technical issues associated with the Service,
              or (iv) such action is appropriate to protect the rights, property
              or safety of Boom, its employees, users of the Service or others.
            </span>
          </p>
        </li>
        <li data-list-text="•">
          <p>
            {" "}
            <span>Asset transfers. </span>
            <span>
              If we become involved in a merger, acquisition or other
              transaction involving the sale of some or all of Boom's assets,
              user information, including personal information collected from
              you through your use of the Service, could be included in the
              transferred assets. Should such an event occur, we will use
              reasonable means to notify you through email and/or a prominent
              notice on the Site.
            </span>
          </p>
        </li>
        <li data-list-text="•">
          <p>
            {" "}
            <span>Service Providers. </span>
            <span>
              We may share your personal information with vendors or service
              providers (including our affiliates that provide services on our
              behalf) that we engage for the purpose of processing information
              on our and your behalf; hosting the Services; designing or
              operating the Services' features; tracking the Services'
              analytics; providing data used, directly or indirectly, in
              connection with the Services; marketing the Services, enabling us
              to contact you with offers; or performing other administrative
              services. Additionally, we may partner with other companies to
              process, analyze, and/or store data, including, but not limited
              to, Analytical Data. We may provide these third party service
              providers or vendors with access to user information, including
              personal information, in connection with the services they are
              performing for you or us. To the extent applicable, we require
              these entities to comply with this Privacy Policy and appropriate
              confidentiality and security measures.
            </span>
          </p>
        </li>
        <li data-list-text="•">
          <p>
            Publicly Posted Information.{" "}
            <span>
              We may share information that is publicly posted by you. Examples
              of such instances include, but are not limited to: reviews,
              suggestions, questions, surveys, and comments posted on other
              sites and forums. Should we decide to share your publicly posted
              information on our Site, we will notify you via email. Should any
              issues arise as a result of sharing your publicly posted
              information, we will need to be notified, at which point we will
              prevent or remove the sharing of this information.
            </span>
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <h3>Additional Information You Should Know About Third Parties</h3>
      <p>
        The Service may contain direct links to websites operated by our
        partners and other entities. These third party websites may collect
        information about you when you visit them or otherwise communicate with
        them. This Privacy Policy does not
      </p>
      <p>
        cover the information practices of third party websites linked to the
        Site or accessible through the Service. Although we encourage such third
        parties to adopt and follow their own privacy policies, we are not
        responsible for their collection and use of your personal information.
        You should refer to the privacy policies and statements of other
        websites or contact the respective webmasters of those websites to
        obtain information regarding their information collection, use and
        disclosure policies.
      </p>
      <p>
        <br />
      </p>
      <h2>
        What choices do you have about the collection and use of your personal
        information?
      </h2>
      <h3>Control of Your Information</h3>
      <p>
        You may update or delete your personal information or modify your
        account preferences for the Service by accessing the account interface
        provided through the Service. Please note that if you disable your
        account, we will keep your contact information on file, and may retain
        other account information for a reasonable period of time afterward for
        the purpose of internal account management and fraud prevention.
      </p>
      <p>
        <br />
      </p>
      <h3>Your California Privacy Rights</h3>
      <p>
        If you are a California resident, you may be entitled to additional
        rights over your personal information. In summary, The CCPA gives
        Californians several basic rights: the right to know what personal
        information is being collected about them, the right to access that
        data, the right to know who it's being sold to, and the right to opt-out
        of those sales. Boom does not sell your personal information.
      </p>
      <p>
        The CCPA also gives Californians the right to request that a business
        delete any personal information the business has collected about a
        consumer unless the information is required to complete the transaction
        for which the personal
      </p>
      <p>
        information was collected. If you would like to request the deletion of
        your personal data, please do so in the app (go to 'Settings') or submit
        a request to Boom by contacting us via our chat functionality in the
        Boom app or on the Boom website.
      </p>
      <p>
        <br />
      </p>
      <h3>Opting Out of Receiving Communications From Us</h3>
      <p>
        <a href="mailto:hello@boompay.app" target="_blank">
          Boom uses a "single opt-in" method for sending you email and SMS
          messages. When you register for an account, you explicitly authorize
          us to contact you via email and SMS messages. If you no longer want to
          receive our emails and/or SMS messages, you may unsubscribe by writing
          to us at{" "}
        </a>
        hello@boompay.app or following the "unsubscribe" link at the bottom of
        our emails. Please note that you cannot unsubscribe from certain
        correspondence from us, including transactional emails and messages
        relating directly to your account. Please also note that opting out will
        not apply to any communications from third parties to whom Boom may have
        provided information regarding the user. Third parties' use of the
        information is subject to such parties' own privacy policies, for which
        Boom shall not be responsible.
      </p>
      <p>
        <br />
      </p>
      <h3>Disputes</h3>
      <p>
        You agree that, in the event any dispute or claim arises out of or
        relating to this Privacy Policy, you and Boom will attempt in good faith
        to negotiate a written resolution of the matter directly between the
        parties.
      </p>
      <p>
        <br />
      </p>
      <h2>
        What security measures do we take to safeguard your personal
        information?
      </h2>
      <p>
        The personal information that you provide to us is stored on servers
        which are protected by protocols and procedures designed to ensure the
        security of such
      </p>
      <p>
        information. We restrict access to personal information to Boom
        employees, independent contractors and agents who need to know this
        information in order to develop, operate and maintain the Service. All
        Boom personnel who have access to sensitive information such as Social
        Security numbers are trained in the maintenance and security of such
        information to prevent their mishandling. However, no server, computer
        or communications network or system, or data transmission over the
        Internet can be guaranteed to be 100% secure. As a result, while we
        strive to protect user information, we cannot ensure or warrant the
        security of any information you transmit to us or through the use of the
        Service and you acknowledge and agree that you provide such information
        and engage in such transmissions at your own risk. Once we receive a
        transmission from you, we will endeavor to maintain its security on our
        systems.
      </p>
      <p>
        <br />
      </p>
      <p>
        <span>
          In the event that personal information you provide to us is
          compromised as a result of a breach of security, when appropriate we
          will take reasonable steps to investigate the situation, notify you
          and take the necessary steps to{" "}
        </span>
        <span>comply with</span>
        <span> </span>
        <span>any applicable laws and regulations</span>
        <span>.</span>
      </p>
      <p>
        <br />
      </p>
      <p>How We Handle Policy Changes</p>
      <p>
        We'll update this Privacy Policy as needed so it remains current,
        accurate and as clear as possible. Any updates will be clearly posted to
        our website. Updates are effective immediately. Your continued use of
        the Service confirms your acceptance of our updated Privacy Policy.
      </p>
      <p>How Can You Contact Us</p>
      <p>
        <a href="mailto:hello@boompay.app" target="_blank">
          If you have any questions about our privacy practices or this Privacy
          Policy, please contact us via the chat functionality within the Boom
          application or by emailing{" "}
        </a>
        <a href="mailto:hello@boompay.app" target="_blank">
          hello@boompay.app.
        </a>
      </p>
    </>
  );
};
