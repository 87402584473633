import React, { useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";

export const AnonLaunchLink = (props) => {
  const { open, ready } = usePlaidLink({
    token: props.token,
    onSuccess: props.onSuccess,
    onExit: props.onExit,
  });
  useEffect(() => {
    if (ready) open();
  }, [ready]);

  return <></>;
};
