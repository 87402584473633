import { useEffect, useState } from "react";
import api from "src/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import dayjs from "dayjs";
// import { useLayout } from "../../components/layout/layout";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
import { Card } from "src/shared/Card";
import { Badge } from "src/shared/Badge";
// import { postEvent } from "src/helpers/postEvent";
import { General } from "untitledui-js";
import { KeyValue } from "./components/KeyValue";
import { postEvent } from "src/helpers/postEvent";

export const HomeIdView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const [enrollments, setEnrollments] = useState<{
    status: "loading" | "success" | "error";
    error?: unknown;
    data?: any[];
  }>({
    status: "loading",
  });

  const [verifications, setVerifications] = useState<{
    status: "loading" | "success" | "error";
    error?: unknown;
    data?: any;
  }>({
    status: "loading",
  });

  useEffect(() => {
    postEvent({
      type: "analytics",
      action: "pageview",
      source: "/home/:id",
      message: pathname,
    });

    setEnrollments({
      status: "loading",
      data: undefined,
      error: undefined,
    });

    api
      .getDashboard()
      .then(api.pickData)
      .then((d) => {
        setEnrollments({
          status: "success",
          data: d.enrollments,
          error: undefined,
        });
      })
      .catch((e: unknown) => {
        setEnrollments({
          status: "error",
          data: undefined,
          error: e,
        });
      });

    setVerifications({
      status: "loading",
      data: undefined,
      error: undefined,
    });

    api
      .getRentalVerficationsOfEnrollmentCase({ id: params.id })
      .then(api.pickData)
      .then((d) => {
        setVerifications({
          status: "success",
          data: d,
          error: undefined,
        });
      })
      .catch((e) => {
        setVerifications({
          status: "error",
          data: undefined,
          error: e,
        });
      });
  }, []);

  if (enrollments.status === "loading" || verifications.status === "loading") {
    return null;
  }

  const enrollment = enrollments.data?.find((e) => String(e.id) === params.id);

  if (!enrollment) return null;
  return (
    <>
      <Title type="h3" color="subText">
        {enrollment.address.city}, {enrollment.address.stateOrProvince}{" "}
        {enrollment.address.postalCode}
      </Title>
      <Title
        type="h1"
        style={{
          marginBottom: 4,
        }}
      >
        {enrollment.address?.address1}
      </Title>
      {/* STATS */}
      <div
        style={{
          marginTop: 24,
          marginBottom: 32,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <KeyValue
          label="Move-in"
          value={format(parseISO(enrollment.moveInDate), "MMM dd, yyyy")}
        />

        <KeyValue
          label="Lease end"
          value={
            enrollment?.enrollmentCase?.leaseEndDate
              ? format(
                  parseISO(enrollment?.enrollmentCase?.leaseEndDate),
                  "MMM dd, yyyy"
                )
              : "Month-to-month"
          }
        />

        <KeyValue
          label="Rent"
          value={`$${enrollment.monthlyRentAmount.cents / 100}`}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: 12,
        }}
      >
        <Card
          style={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 16,
          }}
        >
          <Title type="h2">Ongoing reporting</Title>
          <Text
            type="sm"
            style={{
              marginTop: 8,
              marginBottom: 16,
              flexGrow: 1,
            }}
          >
            Report rent payments from this month onward
          </Text>
          <Badge
            style={{
              justifyContent: "center",

              display: "inline-flex",
            }}
            color={enrollment.subscribedForOngoing ? "success" : "pending"}
          >
            {enrollment.subscribedForOngoing ? "Active" : "Inactive"}
          </Badge>
        </Card>

        <Card
          style={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 16,
          }}
        >
          <Title type="h2">Past Reporting</Title>
          <Text
            type="sm"
            style={{
              flexGrow: 1,
              marginTop: 8,
              marginBottom: 16,
            }}
          >
            Report up to 24 months of prior payments{" "}
          </Text>
          <Badge
            style={{
              justifyContent: "center",
              display: "inline-flex",
            }}
            color={enrollment.subscribedForPast ? "success" : "primary"}
            onClick={() => {
              if (!enrollment.subscribedForPast) {
                navigate(`/enrollment/checkout`, {
                  state: {
                    issueResolution: true,
                  },
                });
              }
            }}
          >
            {enrollment.subscribedForPast ? "Active" : "Add"}
          </Badge>
        </Card>
      </div>

      {enrollment.card ? (
        <>
          <Title
            type="h3"
            style={{
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            Linked bank accounts
          </Title>
          <Card>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 20,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 10,
                }}
              >
                <General.Building08 size="20" stroke={"#999"} />

                <Title type="h2">{enrollment.card.label}</Title>
              </div>

              <Title type="h2">****{enrollment.card.display.last4}</Title>
            </div>
          </Card>
        </>
      ) : null}

      {enrollment?.enrollmentCase?.legalEntity ? (
        <>
          <Title
            type="h3"
            style={{
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            Landlord info
          </Title>
          <Card
            style={{
              padding: 20,
            }}
          >
            {enrollment?.enrollmentCase?.legalEntity.companyName ? (
              <Text type="sm">
                {enrollment?.enrollmentCase?.legalEntity.companyName}
              </Text>
            ) : (
              <Text
                type="sm"
                style={{
                  margin: "8px 0",
                }}
              >
                {enrollment?.enrollmentCase?.legalEntity.contactFirstName}{" "}
                {enrollment?.enrollmentCase?.legalEntity.contactLastName}
              </Text>
            )}
            {enrollment?.enrollmentCase?.legalEntity.email ? (
              <Text
                type="sm"
                style={{
                  margin: "8px 0",
                }}
              >
                {enrollment?.enrollmentCase?.legalEntity.email}
              </Text>
            ) : null}
            <Text type="sm">
              {enrollment?.enrollmentCase?.legalEntity.phone}
            </Text>
          </Card>
        </>
      ) : null}
      <Title
        type="h3"
        style={{
          marginTop: 24,
          marginBottom: 8,
        }}
      >
        Reporting history
      </Title>
      {verifications?.data?.items?.length ? (
        verifications.data?.items?.map((rv) => (
          <RentalVerification
            key={rv.id}
            isVerified={rv.isVerified}
            forMonth={rv.forMonth}
          />
        ))
      ) : (
        <Text type="sm">No rental verifications found for this enrollment</Text>
      )}
    </>
  );
};

function RentalVerification({ isVerified, forMonth }) {
  const text = isVerified ? "Verified" : "Pending";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "16px 0",
      }}
    >
      <Text
        type="sm"
        style={{
          fontWeight: 500,
        }}
      >
        {dayjs(forMonth).format("MMMM YYYY")}
      </Text>
      <Badge color={isVerified ? "success" : undefined}>{text}</Badge>
    </div>
  );
}
