// Smart logo decides what logo to render

// It has the following priorities:
// Partner -> Beam -> Boom

// Partner - token & partnerLogoUrl
// Beam/Staging/Unconfigured - token & !customerLogoUrl
// Boom - !partner

import { FC } from "react";
import BoomIcon from "src/assets/BoomIcon";
import PartnerFallbackLogo from "src/assets/PartnerFallbackLogo";
import { useLayout } from "src/components/layout/layout";
import { useAuth } from "src/providers/auth";

export const SmartLogo: FC<{ size: number }> = ({ size }) => {
  const { logoUri } = useLayout();
  const { token } = useAuth();

  if (token && logoUri)
    return <img style={{ height: size }} src={logoUri} alt="Logo" />;

  if (token)
    return <PartnerFallbackLogo style={{ height: size, width: size }} />;

  return (
    <BoomIcon
      style={{
        height: size,
        width: size,
      }}
    />
  );
};
