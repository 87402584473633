import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../providers/auth";
import { InputField } from "../../components/FormFields/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useError } from "../../providers/useError";
import PhoneField from "../../components/FormFields/PhoneField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { config } from "../../config";
import { schema } from "./schema";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
import { Button } from "src/shared/Button";
export const AuthView = () => {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const { setError } = useError();
  const partnerId = config.partnerId;

  const [query] = useSearchParams();

  const paramFromURl = query.get("email");

  useEffect(() => {
    if (paramFromURl) {
      setValue(
        "email",
        decodeURIComponent(
          encodeURIComponent(paramFromURl).replace(/%20/g, "+")
        )
      );
    }
  }, []);

  return (
    <div>
      <Title
        type="h1"
        style={{
          textAlign: "center",
        }}
      >
        Create your account
      </Title>

      <Title
        type="h3"
        style={{
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        Enroll in minutes
      </Title>

      <form
        onSubmit={handleSubmit(
          (data: object | undefined) => {
            const customerData = partnerId
              ? { ...(data || {}), partnerId }
              : data;
            return signUp(
              customerData as {
                firstName: string;
                lastName: string;
                email: string;
                phone: string;
              }
            ).catch((e) => {
              setError(e);
            });
          },
          (e) => {
            console.log(e);
          }
        )}
      >
        <InputField
          name="firstName"
          label="Legal first name"
          fullWidth
          control={control}
        />
        <InputField
          name="lastName"
          fullWidth
          control={control}
          label="Legal last name"
        />
        <PhoneField name="phone" control={control} />
        <InputField name="email" fullWidth control={control} label="Email" />

        <Button variant="primary" fullWidth type="submit">
          Submit
        </Button>
      </form>

      <Text
        type="label"
        style={{
          textAlign: "center",
        }}
      >
        Already have an account?{" "}
        <Button
          type="button"
          onClick={() => {
            navigate("/auth/login");
          }}
        >
          Log in!
        </Button>
      </Text>
    </div>
  );
};
