import { Modal } from "react-responsive-modal";
import React, { FC, useMemo } from "react";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TermsOfService } from "./TermsOfService";
import { General } from "untitledui-js";

export enum LEGAL_DOCS {
  PP = "privacy policy",
  TOS = "terms of service",
}

export const LegalModal: FC<{
  isOpen: boolean;
  onClose: () => void | unknown;
  docName: LEGAL_DOCS;
}> = ({ isOpen, onClose, docName }) => {
  const doc = useMemo(() => {
    switch (docName) {
      case LEGAL_DOCS.PP:
        return <PrivacyPolicy />;
      case LEGAL_DOCS.TOS:
        return <TermsOfService />;
      default:
        return (
          <div>
            <h1>Document not found</h1>
          </div>
        );
    }
  }, [docName]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      styles={{
        modal: {
          padding: 20,
          boxShadow: "0px 4px 31px 0px #060606",
          maxWidth: 800,
          margin: "0 auto",
          height: "100vh",
          boxSizing: "border-box",
        },
        overlay: {
          background: "#000000BA",
        },
        closeButton: {
          display: "none",
        },
      }}
    >
      <div>
        <General.XClose
          size="24"
          onClick={() => {
            onClose && onClose();
          }}
        />
      </div>
      <div
        style={{
          maxHeight: "calc(100vh - 100px)",
          marginTop: 10,
          overflowY: "auto",
        }}
        className="legal-docs" // styles text inside
      >
        {doc}
      </div>
    </Modal>
  );
};
