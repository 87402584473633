import React from "react";
import { Text } from "../../shared/Text";
import styled from "@emotion/styled";
import { useController } from "react-hook-form";
import { ETheme } from "../layout/layout";

export const StyledInput = styled.input`
  height: 48px;
  box-sizing: border-box;
  padding: 14px 16px;
  width: 100%;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid
    ${(props: { error: boolean; theme: ETheme }) =>
      props.error ? props.theme.colors.danger : props.theme.colors.border};

  color: ${(props: { theme: ETheme }) => props.theme.colors.text};

  background-color: ${(props: {
    error: boolean;
    theme: { colors: { cardBackground: string } };
  }) => props.theme.colors.cardBackground};

  margin-bottom: 16px;

  &:hover {
    outline: none;

    border-color: ${(props: {
      error: boolean;
      theme: { colors: { primary: string } };
    }) => props.theme.colors.primary};
  }

  &:focus {
    outline: none;

    border-color: ${(props: {
      error: boolean;
      theme: { colors: { primary: string } };
    }) => props.theme.colors.primary};
  }
`;

export const StyledErrorMessage = styled(Text)`
  color: ${(props: { theme?: ETheme }) => props.theme?.colors?.danger};
  font-size: 12px;
  margin-top: 0;
  /* margin-bottom: 10px; */
  transform: translateY(-10px);
`;

export const InputField = (props) => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <>
      <StyledInput
        {...props}
        {...field}
        label={props.label}
        error={Boolean(error)}
        helperText={error?.message}
        {...props.inputProps}
        placeholder={props.label || props.inputProps.placeholder}
      />
      {error?.message ? (
        <StyledErrorMessage type="sm">{error.message}</StyledErrorMessage>
      ) : null}
    </>
  );
};
