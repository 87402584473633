import React from "react";
import { ThemeProvider as EThemeProvider } from "@emotion/react";

export type ETheme = {
  colors: {
    primary: string;
    secondary: string;
    text: string;
    subText: string;
    border: string;

    // inputs and cards
    cardBackground: string;

    danger: string;
    success: string;
    warning: string;
    info: string;
  };
};

const LayoutContext = React.createContext<{
  primaryColor: string;
  secondaryColor: string;
  logoUri: string;
  theme: string;
}>({
  primaryColor: "",
  secondaryColor: "",
  logoUri: "",
  theme: "",
});

export function useLayout() {
  return React.useContext(LayoutContext);
}

export function LayoutProvider({
  children,
  primaryColor,
  secondaryColor,
  partnerTheme,
  logoUri,
}) {
  return (
    <LayoutContext.Provider
      value={{
        primaryColor,
        secondaryColor,
        logoUri,
        theme: partnerTheme,
      }}
    >
      <EThemeProvider
        theme={
          {
            colors: {
              primary: primaryColor,
              secondary: secondaryColor,
              text: partnerTheme === "dark" ? "#fff" : "#282828",
              subText: partnerTheme === "dark" ? "#A5A5A5" : "#999",
              border: partnerTheme === "dark" ? "#212121" : "#E8E8E8",

              cardBackground: partnerTheme === "dark" ? "#FFFFFF12" : "#fff",

              danger: "#FF5816",
              success: "#64C132",
              warning: "#FFAB3D",
              info: "#618AF9",
            },
          } as ETheme
        }
      >
        {children}
      </EThemeProvider>
    </LayoutContext.Provider>
  );
}
