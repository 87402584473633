import dayjs from "dayjs";
import { Card } from "src/shared/Card";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";

export const SubscriptionsSlice = ({ enrollments }) => {
  return (
    <>
      <Title
        type="h1"
        style={{
          marginBottom: 20,
        }}
      >
        Manage subscriptions
      </Title>
      <Card>
        <Title type="h3">BoomReport</Title>
        <div
          style={{
            height: 1,
            backgroundColor: "#eaeaea",
            width: "100%",
            margin: "20px 0",
          }}
        />

        {/* TODO: add free checkout option */}
        <div>
          {enrollments.map((enrollment) => (
            <>
              <Title type="h3">
                {enrollment.address?.address1} {enrollment.address.city},{" "}
                {enrollment.address.stateOrProvince}{" "}
                {enrollment.address.postalCode}
              </Title>
              <Title type="h4">
                Subscribed on{" "}
                {dayjs(enrollment.reportingAccountCreatedAt).format(
                  "MMMM DD, YYYY"
                )}
              </Title>
              {enrollment.card ? (
                <Card>
                  <Text type="sm">
                    Ends on{" "}
                    {dayjs(enrollment.reportingAccountCreatedAt)
                      .add(1, "year")
                      .format("MMMM DD, YYYY")}
                    &nbsp;with
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Text type="sm">
                      Card ending with *{enrollment.card?.display?.last4}
                    </Text>
                  </div>
                </Card>
              ) : null}
            </>
          ))}
        </div>
      </Card>
    </>
  );
};
