import { useIntercom } from "react-use-intercom";
import { useNavigate } from "react-router-dom";
import { issueScreenMap } from "src/helpers/issueScreenMap";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
import { Card } from "src/shared/Card";
import { Alerts } from "untitledui-js";
import { toast } from "react-toastify";
import api from "src/api";
import { CopyInput } from "src/shared/CopyInput";
import { useState } from "react";
// import { CopyInput } from "src/shared/CopyInput";

const intercomMessages = {
  "unverified-rv-issue":
    "APP-INBOUND: Hi there, I'm trying to resolve an issue with one or more of my unverified payments. Can you help me?",
  "lease-verification-required":
    "APP-INBOUND: Hi there, I'm trying to resolve issues with my lease details. Can you help me?",
  "close-account":
    "APP-INBOUND: Hi there, I'm about to delete my account but wanted to chat briefly first. Can you help me?",
  "cancel-subscription":
    "APP-INBOUND: Hi there, I'm about to turn off auto-renew for my subscription but wanted to chat briefly first. Can you help me?",
  "verify-identity":
    "APP-INBOUND: Hi there, I'm trying to resolve an issue with my identity verification and my submitted data is already correct. Can you help me?",
  "payment-issue-m2m":
    "APP-INBOUND: Hi there, I'm trying to resolve an issue with one or more of my payments (I'm still month-to-month). Can you help me?",
};

export const onboardingMessage = "IN-APP: I have questions about onboarding.";

export const IssueCard = (props) => {
  const navigate = useNavigate();
  const Intercom = useIntercom();

  const [link, setLink] = useState("");
  const identityUrl = props?.issue?.params?.shareableUrl;

  function handlePress() {
    if (props.issue.screen === "intercom") {
      Intercom.showArticle(intercomMessages[props.issue.slug]);
    } else if (identityUrl) {
      // Make copy to clipboard
      //   Linking.openURL(identityUrl);
      navigator.clipboard.writeText(identityUrl);
    } else {
      const link = issueScreenMap[props.issue.screen];
      if (link && link !== "/404") {
        navigate(link, {
          state: {
            issueResolution: true,
          },
        });
      }
    }
  }

  if (props.issue.slug === "bank-link-bypassed") {
    return (
      <Card
        style={{
          padding: "20px",
          backgroundColor: "#FF581614",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
          }}
        >
          <div
            style={{
              transform: "rotate(180deg)",
            }}
          >
            <Alerts.AlertCircle size="20px" stroke="#FF5816" />
          </div>
          <Title
            type="h2"
            style={{
              margin: 0,
            }}
          >
            Link your bank account to start reporting rent payments
          </Title>
        </div>

        <Text
          type="label"
          style={{
            marginTop: 8,
            marginBottom: 16,
          }}
        >
          Use the secure link we sent to your email and phone, or copy the link
          below and paste it into your browser.
        </Text>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
          }}
        >
          <CopyInput
            style={{
              width: "100%",
            }}
            onClick={() => {
              api
                .sendBankLinkUrl()
                .then(() => {
                  toast.clearWaitingQueue();
                  toast.success("Link successfully sent.", {
                    autoClose: 1500,
                    toastId: "bank-link-resent",
                  });
                })
                .catch(() => {
                  toast.clearWaitingQueue();
                  toast.error("Something went wrong. Please try again.", {
                    autoClose: 1500,
                    toastId: "bank-link-resent",
                  });
                });
            }}
          >
            <Text
              type="label"
              style={{
                // ellipiss
                overflow: "hidden",
                textAlign: "center",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
              color="text"
            >
              Resend Link
            </Text>
          </CopyInput>

          <CopyInput
            style={{
              width: "100%",
            }}
            onClick={() => {
              if (!link) {
                api
                  .sendBankLinkUrl()
                  .then(api.pickData)
                  .then((d) => {
                    setLink(d?.plaidConnectUrl);
                    return d?.plaidConnectUrl;
                  })
                  .then((l) => {
                    toast.clearWaitingQueue();
                    toast.promise(navigator.clipboard.writeText(l), {
                      pending: "Copying link to clipboard...",
                      success: "Link copied to clipboard.",
                      error: "Something went wrong. Please try again.",
                    });
                  });
              } else {
                toast.promise(navigator.clipboard.writeText(link), {
                  pending: "Copying link to clipboard...",
                  success: "Link copied to clipboard.",
                  error: "Something went wrong. Please try again.",
                });
              }
            }}
          >
            <Text
              type="label"
              style={{
                // ellipiss
                overflow: "hidden",
                textAlign: "center",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
              color="text"
            >
              Copy Link
            </Text>
          </CopyInput>
        </div>
      </Card>
    );
  }

  return (
    <Card
      style={{
        padding: "20px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "12px 0",
      }}
      onClick={() => {
        if (
          !(
            (!identityUrl &&
              props.issue.slug === "identity-verification-required") ||
            !issueScreenMap[props.issue.screen] ||
            issueScreenMap[props.issue.screen] === "/404"
          )
        ) {
          handlePress();
        }
      }}
    >
      <div
        style={{
          transform: "rotate(180deg)",
        }}
      >
        <Alerts.AlertCircle size={"20px"} stroke={"#FF5816"} />
      </div>
      <div
        style={{
          paddingLeft: 12,
          marginLeft: 12,
          borderLeft: "1px solid #E8E8E8",
        }}
      >
        <Title type="h2">{props.issue.title}</Title>

        <Text type="sm">{props.issue.description}</Text>

        {/* {!identityUrl &&
        props.issue.slug === "identity-verification-required" ? (
          <Text
            type="sm"
            style={{
              marginTop: 4,
            }}
          >
            Check your email for instructions*
          </Text>
        ) : null} */}
      </div>
    </Card>
  );
};

// const useStyles = makeStyles((theme) => {
//   return {
//     chevron: {
//       color: "#fff",
//     },
//     chevronLight: {
//       color: "#474957",
//     },
//   };
// });
