import { Title } from "src/shared/Title";
import { NoAccounts } from "./slices/NoAccounts";
import { useEffect, useState } from "react";
import api from "src/api";

import { BankAccount } from "./types";
import { Accounts } from "./slices/Accounts";
import { LoadingSlice } from "./slices/Loading";
import { useLayout } from "src/components/layout/layout";

export const SettingsLinkedAccountsView = () => {
  const { primaryColor } = useLayout();

  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchAccounts = () =>
    api
      .getBankAccounts()
      .then(api.pickData)
      .then((b: { bankAccounts: BankAccount[] }) => {
        setBankAccounts(b.bankAccounts);
      })
      .catch((e) => {
        console.log(e);
      });

  useEffect(() => {
    setLoading(true);
    fetchAccounts().finally(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LoadingSlice primaryColor={primaryColor} />;
  }

  return (
    <>
      <Title
        type="h1"
        style={{
          marginBottom: 16,
        }}
      >
        Linked bank accounts
      </Title>
      {bankAccounts.length > 0 ? (
        <Accounts
          accounts={bankAccounts}
          fetchPaymentInstruments={fetchAccounts}
        />
      ) : (
        <NoAccounts />
      )}
    </>
  );
};
