import { AuthProvider } from "./providers/auth";
import { BrowserRouter } from "react-router-dom";
import { TopLevelBoundary } from "./components/Infra/TopLevelBoundary";
import { IntercomProvider } from "react-use-intercom";
import { ToastContainer, Slide } from "react-toastify";
import { config } from "./config";
import { LayoutProvider } from "./components/layout/layout";
import { AppRouter } from "./providers/router";
import { CustomerProvider } from "./providers/customer";
import { UILayout } from "./providers/ui-layout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";

const stripePromise = loadStripe(config.stripePublishableKey);

export default function App(props) {
  return (
    <TopLevelBoundary>
      <BrowserRouter>
        <Elements
          stripe={stripePromise}
          options={{
            locale: "en",
          }}
        >
          <IntercomProvider appId={config.intercomId}>
            <LayoutProvider
              partnerTheme={props.theme}
              secondaryColor={props.secondaryColor}
              primaryColor={props.primaryColor}
              logoUri={props.logoUri}
            >
              <AuthProvider token={props.token}>
                <CustomerProvider>
                  <UILayout>
                    <ToastContainer
                      limit={3}
                      style={{
                        marginTop: 0,
                        maxWidth: "100vw",
                        width: "100%",
                        margin: "0",
                        padding: "0",
                        borderRadius: "0px !important",
                      }}
                      transition={Slide}
                      className="toast-container"
                      borderRadius={0}
                      hideProgressBar
                      closeButton={false}
                      closeOnClick
                      position="top-center"
                      autoClose="2500"
                      newestOnTop
                      theme={"colored"}
                    />
                    <AppRouter />
                  </UILayout>
                </CustomerProvider>
              </AuthProvider>
            </LayoutProvider>
          </IntercomProvider>
        </Elements>
      </BrowserRouter>
    </TopLevelBoundary>
  );
}
