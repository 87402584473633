import React, { useEffect } from "react";
import { toast } from "react-toastify";

const defaultMessage = "Something went wrong. Please try again.";

export function useError(initialState) {
  const [error, setErrorInner] = React.useState(initialState || null);
  const setError = React.useCallback((e) => {
    setErrorInner(e);
    return null;
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(extractErrorMessage(error));
      setError("");
    }
  }, [error]);

  return { error, setError };
}

/**
 * @return {string|null}
 */
export function extractErrorMessage(error, defaultMsg) {
  if (!error || typeof error === "string") return error;

  const status = error?.response?.data?.error?.message;

  return status >= 500
    ? defaultMessage
    : error?.response?.data?.error?.message || defaultMsg || defaultMessage;
}
