import { useAuth } from "src/providers/auth";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import api from "src/api";
import { useLayout } from "src/components/layout/layout";

import { usePullToRefresh } from "use-pull-to-refresh";

import { BoomReportCard } from "./components/BoomReportCard";
import { IssueCard } from "./components/IssueCard";

import { LoadingSlice } from "./slices/Loading";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
import { Card } from "src/shared/Card";
import { postEvent } from "src/helpers/postEvent";
import { useLocation } from "react-router-dom";

const MAXIMUM_PULL_LENGTH = 240;
const REFRESH_THRESHOLD = 180;

const getRelativeDayPeriod = () => {
  const hours = new Date().getHours();
  return hours < 12 ? "Morning" : hours < 18 ? "Afternoon" : "Evening";
};

export const Home = () => {
  const { primaryColor } = useLayout();
  const { customer } = useAuth();
  const [enrollments, setEnrollments] = useState<{
    status: "loading" | "success" | "error";
    error?: unknown;
    data?: any[];
  }>({
    status: "loading",
  });
  const [issues, setIssues] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    postEvent({
      type: "analytics",
      action: "pageview",
      source: pathname,
    });
  }, []);

  const getData = () => {
    setEnrollments({
      status: "loading",
      data: undefined,
      error: undefined,
    });
    api
      .getDashboard()
      .then(api.pickData)
      .then((d) => {
        setEnrollments({
          status: "success",
          data: d.enrollments,
          error: undefined,
        });
        setIssues(
          d.customerIssues
            .concat(d.enrollments.reduce((acc, e) => acc.concat(e.issues), []))
            .sort((a, b) => {
              if (a.slug === "bank-link-bypassed") return -1;
              if (b.slug === "bank-link-bypassed") return 1;
              return 0;
            })
        );
      })
      .catch((e: unknown) => {
        setEnrollments({
          status: "error",
          data: undefined,
          error: e,
        });
      });
  };

  useEffect(() => {
    // postEvent({
    //   type: "analytics",
    //   name: "pageview: home",
    // });
    getData();
  }, []);

  usePullToRefresh({
    onRefresh: getData,
    maximumPullLength: MAXIMUM_PULL_LENGTH,
    refreshThreshold: REFRESH_THRESHOLD,
    isDisabled: enrollments.status === "loading",
  });

  if (enrollments.status === "loading")
    return <LoadingSlice primaryColor={primaryColor} />;

  return (
    <>
      <div>
        <Title
          type="h2"
          color="subText"
          style={{
            marginBottom: 8,
          }}
        >
          Good {getRelativeDayPeriod()}, {customer?.name}
        </Title>
        <Title type="h1" style={{ marginTop: 8 }}>
          {format(new Date(), "MMMM d")}
        </Title>
      </div>

      {issues.length ? (
        <div
          style={{
            marginTop: 24,
          }}
        >
          {issues.map((issue) => (
            <IssueCard
              issue={issue}
              address={issue.enrollment?.address}
              key={issue.id}
            />
          ))}
        </div>
      ) : null}

      <Text
        type="label"
        style={{
          marginTop: 24,
          marginBottom: 12,
        }}
      >
        BoomReport
      </Text>
      {enrollments.data?.length
        ? enrollments.data.map((enrollment) => (
            <Card
              style={{
                padding: 20,
                margin: "12px 0",
              }}
            >
              <BoomReportCard {...enrollment} key={enrollment.id} />
            </Card>
          ))
        : null}
    </>
  );
};
