import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../auth";
import { AuthState } from "../auth/types";
import { mapAuthStateToDefaultPath } from "./helpers";

// accessible with (issueResolution && authState === AuthState.FULL) || authState === AuthState.ONBOARDING_REQUIRED
export const ProtectedRoute = ({
  children,
  requiredState,
  fallback,
}: {
  children: JSX.Element;
  requiredState?: AuthState;
  fallback?: JSX.Element;
}) => {
  const { authState } = useAuth();
  const location = useLocation();

  if (requiredState) {
    if (authState === requiredState) return children;
    else {
      if (fallback) return fallback;
      return <Navigate to={mapAuthStateToDefaultPath[authState]} />;
    }
  }

  // Bypass for issue resolution
  if (
    (authState === AuthState.FULL &&
      (location?.state as { issueResolution: string })?.issueResolution) ||
    authState === AuthState.ONBOARDING_REQUIRED
  ) {
    location.state = undefined;
    return children;
  }

  // just in case
  return <Navigate to={mapAuthStateToDefaultPath[authState]} />;
};
