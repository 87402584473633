import { FC, HTMLAttributes } from "react";
import styled from "@emotion/styled";
import { ETheme } from "src/components/layout/layout";

type Props = {
  type: "sm" | "label"; // "sm" | "md" | "lg"; didn't see any other sizes used
  color?: "subText" | "text" | "primary";
} & HTMLAttributes<HTMLParagraphElement>;

const StyledSm = styled.p`
  color: ${(props: { theme?: ETheme; color: string }) =>
    props.theme?.colors?.[props.color || "subText"]};
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
`;

const StyledLabel = styled.p`
  color: ${(props: { theme?: ETheme; color: string }) =>
    props.theme?.colors?.[props.color || "subText"]};
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
`;

export const Text: FC<Props> = ({
  children,
  type,
  color = "subText",
  ...rest
}) => {
  if (type === "sm")
    return (
      <StyledSm {...rest} color={color}>
        {children}
      </StyledSm>
    );
  if (type === "label")
    return (
      <StyledLabel {...rest} color={color}>
        {children}
      </StyledLabel>
    );

  return <p {...rest}>{children}</p>;
};
