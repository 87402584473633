import { FC } from "react";
import { Text } from "src/shared/Text";
import { Title } from "src/shared/Title";

export const KeyValue: FC<{
  label: string;
  value: string;
  className?: string;
  style?: React.CSSProperties;
}> = ({ label, value, className, style, ...props }) => {
  return (
    <div className={className} style={style} {...props}>
      <Text type="label" style={{ margin: 0 }}>
        {label}
      </Text>
      <Title type="h4" style={{ margin: 0 }}>
        {value}
      </Title>
    </div>
  );
};
