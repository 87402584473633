import api from "src/api";
import { View, usePlaidConnectController } from "./controller";
import { ErrorSlice } from "./slices/Error";
import { LoadingSlice } from "./slices/Loading";
import { SuccessSlice } from "./slices/Success";
import { NoMatch } from "src/providers/router/NoMatch";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";

export const PlaidConnectView = () => {
  const {
    view,
    successRedirectUrl,
    token,
    setToken,
    setView,
    refetchToken,
    customer_id,
  } = usePlaidConnectController();

  if (!customer_id) return <NoMatch />;

  if (view === "loading")
    return (
      <LoadingSlice
        token={token}
        onExit={() => {
          setView(View.Error);
          setToken("");
        }}
        onSuccess={(res_token, metadata: PlaidLinkOnSuccessMetadata) => {
          api
            // add passing customer_id
            .exchangePlaidPublicToken({
              ...metadata,
              customer_token: customer_id,
            })
            .then(api.pickData)
            .then(({ bankAccount }) => {
              // TODO: Apparently I can list here and add adding multiple account
              // ----
              // <bind data to user somewhere here>
              // pass bank account to API
              // setEnrollmentCase({ ...enrollmentCase, bankAccount });
            })
            .then(() => {
              setView(View.Success);
            });
        }}
      />
    );
  if (view === "success")
    return <SuccessSlice redirectUrl={successRedirectUrl} />;
  return (
    <ErrorSlice
      redirectUrl={successRedirectUrl}
      retry={() => {
        setView(View.Loading);
        setToken("");
        refetchToken();
      }}
    />
  );
};
