import { ETheme } from "src/components/layout/layout";
import styled from "@emotion/styled";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";
type Colors =
  | "text"
  | "subText"
  | "primary"
  | "secondary"
  | "danger"
  | "success"
  | "warning"
  | "info";
const StyledH1 = styled.h1`
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 28px;
  text-decoration: none;
  text-transform: none;
  color: ${(props: { theme?: ETheme; color?: Colors }) =>
    props.theme?.colors?.[props.color || "text"]};
  margin: 0;
`;

const StyledH2 = styled.h2`
  color: ${(props: { theme?: ETheme; color?: Colors }) =>
    props.theme?.colors?.[props.color || "text"]};
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
`;

const StyledH3 = styled.h3`
  color: ${(props: { theme?: ETheme; color?: Colors }) =>
    props.theme?.colors?.[props.color || "text"]};
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
`;

const StyledH4 = styled.h4`
  color: ${(props: { theme?: ETheme; color?: Colors }) =>
    props.theme?.colors?.[props.color || "text"]};
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
`;

type Props = {
  type: "h1" | "h2" | "h3" | "h4";
  color?: Colors;
} & DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

export const Title: FC<Props> = ({ children, type, ...rest }) => {
  if (type === "h1") return <StyledH1 {...rest}>{children}</StyledH1>;
  if (type === "h2") return <StyledH2 {...rest}>{children}</StyledH2>;
  if (type === "h3") return <StyledH3 {...rest}>{children}</StyledH3>;
  if (type === "h4") return <StyledH4 {...rest}>{children}</StyledH4>;

  return <h5 {...rest}>{children}</h5>;
};
