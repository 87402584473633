import styled from "@emotion/styled";
import { FC } from "react";
import { useLayout } from "src/components/layout/layout";

import Color from "color";

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "submit" | "button";
  variant?: "primary" | "secondary" | string;
  fullWidth?: boolean;
  className?: string;
  style?: React.CSSProperties;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  href?: string;
  target?: string;
  rel?: string;
};

// Base class for all buttons
const BaseButton = styled.button`
  cursor: pointer;
  border: transparent;
  border-radius: 160px;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  font-weight: 600;
`;

const PrimaryButton = styled(BaseButton)`
  background-color: ${(props: { primaryColor: string }) => props?.primaryColor};
  color: #fff;
  &:hover {
    background-color: ${(props) =>
      Color(props.primaryColor).isLight()
        ? Color(props?.primaryColor).darken(0.1).hex()
        : Color(props?.primaryColor).lighten(0.1).hex()};
  }
`;

const SecondaryButton = styled(BaseButton)`
  background-color: ${(props: { secondaryColor: string }) =>
    props.secondaryColor};
  color: ${(props) => props?.color};
  &:hover {
    background-color: ${(props: { secondaryColor: string }) =>
      Color(props.secondaryColor).isLight()
        ? Color(props.secondaryColor).darken(0.1).hex()
        : Color(props.secondaryColor).lighten(0.5).hex()};
  }
`;

const TextButton = styled(BaseButton)`
  display: inline;
  background-color: transparent;
  color: ${(props) => props.primaryColor};
  font-size: 12px;
  padding: 0;
  &:hover {
    color: ${(props: { primaryColor: string }) =>
      Color(props.primaryColor).isLight()
        ? Color(props.primaryColor).darken(0.5).hex()
        : Color(props.primaryColor).lighten(0.5).hex()};
  }
`;

export const Button: FC<ButtonProps> = ({
  children,
  style,
  fullWidth,
  startIcon,
  endIcon,
  ...props
}) => {
  const { secondaryColor, primaryColor } = useLayout();
  if (props.variant === "primary") {
    return (
      <PrimaryButton
        primaryColor={primaryColor}
        style={{
          height: 48,
          ...(fullWidth ? { width: "100%" } : {}),
          ...(style || {}),
        }}
        {...props}
      >
        {children}
      </PrimaryButton>
    );
  }

  if (props.variant === "secondary") {
    return (
      <SecondaryButton
        color={primaryColor}
        secondaryColor={secondaryColor}
        style={{
          height: 48,
          ...(fullWidth ? { width: "100%" } : {}),
          ...(style || {}),
        }}
        {...props}
      >
        {children}
      </SecondaryButton>
    );
  }

  return (
    <TextButton primaryColor={primaryColor} style={style} {...props}>
      {children}
    </TextButton>
  );
};
