import { useEffect, useState } from "react";
import api from "src/api";
import { NoSubscriptionsSlice } from "./slices/NoSubscription";
import { SubscriptionsSlice } from "./slices/Subscriptions";

export const SettingsManageSubscriptionsView = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .getDashboard()
      .then(api.pickData)
      .then((d) => {
        setSubscriptions(d.enrollments?.filter((e) => e.subscribedForOngoing));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return null;
  }

  if (!subscriptions.length) {
    return <NoSubscriptionsSlice />;
  }

  return (
    <div className={"animate-appear"}>
      <div
        style={{
          marginTop: 20,
        }}
      >
        {!subscriptions.length ? (
          <NoSubscriptionsSlice />
        ) : (
          <SubscriptionsSlice enrollments={subscriptions} />
        )}
      </div>
    </div>
  );
};
