import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import "./fonts/Satoshi.ttf";
import "./index.css";

const queryParams = new URLSearchParams(window.location.search);

// SDK props
const token = queryParams.get("token");
const logoUri = queryParams.get("logoUri");
const theme = queryParams.get("theme") ?? "light";

const primaryColor = queryParams.get("primaryColor") ?? "#E6007E";
const secondaryColor =
  queryParams.get("secondaryColor") || (theme === "dark" ? "#000" : "#fff");

document.querySelector("body").style.background = secondaryColor;
createRoot(document.getElementById("root")).render(
  <App
    token={token}
    primaryColor={primaryColor}
    secondaryColor={secondaryColor}
    logoUri={logoUri}
    theme={theme}
  />
);
