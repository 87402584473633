import { CSSProperties, FC, ReactNode } from "react";
import styled from "@emotion/styled";
import { ETheme } from "src/components/layout/layout";

const StyledCard = styled.div`
  background-color: ${(props: { theme?: ETheme }) =>
    props.theme?.colors.cardBackground};
  padding: 24px;
  border: 1px solid ${(props: { theme?: ETheme }) => props.theme?.colors.border};
  border-radius: 8px;
`;

export const Card: FC<{
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}> = ({ style, className, children, onClick }) => {
  return (
    <StyledCard
      style={style}
      className={className}
      onClick={onClick || undefined}
    >
      {children}
    </StyledCard>
  );
};
