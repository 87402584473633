// Used to map boompay mobile screen names to enrollment routes

export const issueScreenMap = {
  Plaid: "/settings/bank-accounts",
  BF20UpdatePayment: "/404", // dont have add payment screen yet
  BFLandlordIssue: "/enrollment/property-managers",
  BF18LeaseClose: "/404", // dont have add lease flow yet
  BF12UploadLease: "/enrollment/upload",
  BFPaymentVerifications: "/404", // dont have add payment verification screen yet
  BF05PortalUnlisted: "/enrollment/portal",
};
