import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "src/api";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export enum View {
  Loading = "loading",
  Error = "error",
  Success = "success",
}

export const usePlaidConnectController = () => {
  const [view, setView] = useState<View>(View.Loading);
  const query = useQuery();
  const [token, setToken] = useState<string>("");

  const customer_id = query.get("customer_token");
  const successRedirectUrl = query.get("redirect_url")
    ? decodeURIComponent(query.get("redirect_url"))
    : "";

  const refetchToken = () => {
    return api
      .newPlaidLinkToken({ customer_token: customer_id })
      .then(api.pickData)
      .then((d) => setToken(d.linkToken));
  };

  useEffect(() => {
    refetchToken();
  }, []);

  return {
    token,
    view,
    setView,
    refetchToken,
    setToken,
    successRedirectUrl,
    customer_id,
  };
};
