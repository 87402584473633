import React from "react";
import UnhandledError from "./UnhandledError";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { config } from "../../config";

export class TopLevelBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };

    Sentry.init({
      dsn: config.sentryDns,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      release: "0.0.1",
      environment: config.appEnv,
      autoSessionTracking: true,
      attachStacktrace: true,
    });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <Sentry.ErrorBoundary
          fallback={
            <UnhandledError
              errorMessage={this.state.error + ""}
              retryText="Please reload the app from this page"
              activityText="Something went wrong."
            />
          }
        >
          {this.props.children}
        </Sentry.ErrorBoundary>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
