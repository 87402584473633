import { Card } from "src/shared/Card";
import { Text } from "src/shared/Text";
import { Title } from "src/shared/Title";
import { Button } from "src/shared/Button";

import LaunchLink from "src/Plaid/Plaid";
import api from "src/api.js";

import { useState } from "react";
import { AddBankModal } from "../components/AddBankModal";
import { useAuth } from "src/providers/auth";
import { Features } from "src/providers/auth/types";

export const NoAccounts = () => {
  const [linkToken, setLinkToken] = useState("");
  const [bankLinkUrl, setBankLinkUrl] = useState("");
  const [open, setOpen] = useState(false);
  const { partner } = useAuth();

  function onSubmit() {
    function fetchLinkToken() {
      const tokenPromise = api.newPlaidLinkToken();
      tokenPromise.then(api.pickData).then((d) => setLinkToken(d.linkToken));
    }
    fetchLinkToken();
  }

  return (
    <>
      <AddBankModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        link={bankLinkUrl}
      />
      <Card>
        <Title type="h2">No linked banks</Title>
        <Text type="sm">
          If you have enrolled in a Boom product, your service may be
          interrupted until you link a bank account.
        </Text>
        <Button
          onClick={() => {
            if (partner.features.includes(Features.BYPASS_BANK_LINKING)) {
              api
                .sendBankLinkUrl()
                .then(api.pickData)
                .then((d) => {
                  setBankLinkUrl(d.plaidConnectUrl);
                  setOpen(true);
                });
            } else {
              onSubmit();
            }
          }}
          style={{ marginTop: 20 }}
          fullWidth
          variant="contained"
        >
          Link new bank
        </Button>

        {linkToken ? (
          <LaunchLink
            token={linkToken}
            setToken={setLinkToken}
            settingsUpdate={(b) => {}}
          />
        ) : null}
      </Card>
    </>
  );
};
