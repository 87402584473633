import { Badge } from "src/shared/Badge";

export function BadgeRender({ issues, approved, rejected, closed }) {
  if (closed) return <Badge>Closed</Badge>; //variant="disabled"
  if (rejected) return <Badge color="error">Rejected</Badge>;
  if (issues.length)
    return <Badge color="pending">Pending Customer Action</Badge>; //variant="pending"
  if (approved) return <Badge color="success">Verified</Badge>; //variant="active"

  return <Badge color="pending">Under Review</Badge>; //variant="processed"
}
