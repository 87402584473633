import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
import { Alerts } from "untitledui-js";
import { useTheme } from "@emotion/react";
import { ETheme } from "src/components/layout/layout";
import { Icon } from "src/shared/Icon";
import { Button } from "src/shared/Button";
import api from "src/api";
import { postEvent } from "src/helpers/postEvent";
import { useError } from "src/providers/useError";

export const SettingsDeleteAccountView = () => {
  const { setError } = useError();
  const theme = useTheme() as ETheme;
  return (
    <div>
      <Title
        type="h1"
        style={{
          marginBottom: 24,
        }}
      >
        Are you sure you want to delete account?
      </Title>

      <div
        style={{
          display: "flex",
          alignItems: "start",
          gap: 16,
          margin: "16px 0",
        }}
      >
        <div
          style={{
            transform: "rotate(180deg) translateY(2px)",
          }}
        >
          <Icon
            Icon={Alerts.AlertCircle}
            size={20}
            color={theme.colors.danger}
          />
        </div>

        <Text
          type="sm"
          style={{
            margin: 0,
          }}
        >
          All your data will be deleted permanently. This action cannot be
          undone.
        </Text>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "start",
          gap: 16,
          margin: "16px 0",
        }}
      >
        <div
          style={{
            transform: "rotate(180deg) translateY(2px)",
          }}
        >
          <Icon
            Icon={Alerts.AlertCircle}
            size={20}
            color={theme.colors.danger}
          />
        </div>

        <Text
          type="sm"
          style={{
            margin: 0,
          }}
        >
          Deleting your account will discontinue all credit reporting going
          forward. <br />
          <br /> Discontinuing credit reporting mid-lease may negatively impact
          your credit score.
        </Text>
      </div>

      <Button
        type="submit"
        style={{
          color: theme.colors.danger,
          margin: "16px auto",
          display: "block",
          padding: "8px",
        }}
        onClick={() => {
          api
            .closeAccount()
            .then(() => {
              postEvent({
                type: "action",
                action: "close",
                message: "User deleted account",
              });
            })
            .catch(setError);
        }}
        fullWidth
      >
        Delete my account
      </Button>
    </div>
  );
};
