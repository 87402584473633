import { useNavigate } from "react-router-dom";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
import { useAuth } from "src/providers/auth";
import { useIntercom } from "react-use-intercom";
import { LEGAL_DOCS, LegalModal } from "src/components/Enrollment/LegalModal";
import { useState } from "react";
import { Card } from "src/shared/Card";
import { Arrow, Files, General, Users } from "untitledui-js";
import { Icon } from "src/shared/Icon";

export const SettingsView = () => {
  const navigate = useNavigate();
  const intercom = useIntercom();
  const { customer } = useAuth();

  const [isPPOpen, setIsPPOpen] = useState(false);
  const [isTOSOpen, setIsTOSOpen] = useState(false);

  return (
    <>
      <LegalModal
        isOpen={isPPOpen}
        onClose={() => setIsPPOpen(false)}
        docName={LEGAL_DOCS.PP}
      />

      <LegalModal
        isOpen={isTOSOpen}
        onClose={() => setIsTOSOpen(false)}
        docName={LEGAL_DOCS.TOS}
      />

      <Title
        type="h1"
        style={{
          marginBottom: 16,
        }}
      >
        Settings
      </Title>

      <Card
        style={{
          marginBottom: 16,
        }}
      >
        <Title
          type="h2"
          style={{
            marginBottom: 4,
          }}
        >
          {customer?.name}
        </Title>
        <Text type="sm">{customer.email}</Text>
      </Card>

      <div>
        <Text
          type="sm"
          style={{
            marginBottom: 12,
          }}
        >
          Account
        </Text>

        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            margin: "12px 0",
            gap: 12,
          }}
          onClick={() => {
            navigate("/settings/linked-accounts");
          }}
        >
          <Icon Icon={General.Building08} size={20} color="subText" />

          <Title type="h2" style={{ flexGrow: 1 }}>
            Linked bank accounts
          </Title>
          <Icon Icon={Arrow.ChevronRight} size={20} />
        </Card>

        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            margin: "12px 0",
            gap: 12,
          }}
          onClick={() => {
            intercom.show();
          }}
        >
          <Icon Icon={General.LifeBuoy02} size={20} color="subText" />
          <Title type="h2" style={{ flexGrow: 1 }}>
            Help & Support
          </Title>
          <Icon Icon={Arrow.ChevronRight} size={20} />
        </Card>

        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            margin: "12px 0",
            gap: 12,
          }}
          onClick={() => {
            navigate("/settings/delete-account");
          }}
        >
          <Icon Icon={Users.UserX01} size={20} color="subText" />
          <Title type="h2" style={{ flexGrow: 1 }}>
            Delete account
          </Title>
          <Icon Icon={Arrow.ChevronRight} size={20} />
        </Card>
      </div>

      <div>
        <Text
          type="sm"
          style={{
            marginBottom: 12,
            marginTop: 32,
          }}
        >
          Legal
        </Text>
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            margin: "12px 0",
            gap: 12,
          }}
          onClick={() => {
            setIsPPOpen(true);
            setIsTOSOpen(false);
          }}
        >
          <Icon Icon={Files.FileSearch02} size={20} color="subText" />
          <Title type="h2" style={{ flexGrow: 1 }}>
            Privacy policy
          </Title>
          <Icon Icon={Arrow.ChevronRight} size={20} />
        </Card>
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            margin: "12px 0",
            gap: 12,
          }}
          onClick={() => {
            setIsTOSOpen(true);
            setIsPPOpen(false);
          }}
        >
          <Icon Icon={Files.File02} size={20} color="subText" />

          <Title type="h2" style={{ flexGrow: 1 }}>
            Terms of use
          </Title>
          <Icon Icon={Arrow.ChevronRight} size={20} />
        </Card>
      </div>
    </>
  );
};
