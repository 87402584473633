import { useNavigate } from "react-router-dom";
import { BadgeRender } from "./Chip";
import { format, parseISO } from "date-fns";
import { Arrow } from "untitledui-js";
import { Title } from "src/shared/Title";
import { Text } from "src/shared/Text";
import { Icon } from "src/shared/Icon";

export const BoomReportCard = (props) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate("/home/" + props.id);
      }}
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <Title
          type="h2"
          style={{
            marginBottom: 4,
            marginTop: 0,
          }}
        >
          {props.address?.address1}
        </Title>

        <Text
          type="sm"
          style={{
            marginBottom: 8,
          }}
        >
          Moved in {format(parseISO(props.moveInDate), "MMMM d, yyyy")}
        </Text>

        <div
          style={{
            display: "flex",
          }}
        >
          <BadgeRender
            issues={props.issues}
            approved={props.reportingEnrollmentApproved}
            rejected={props.reportingEnrollmentRejected}
            closed={props.reportingEnrollmentClosed}
          />
        </div>
      </div>

      <Icon Icon={Arrow.ChevronRight} size={20} />
    </div>
  );
};
