import React from "react";

export const TermsOfService = () => {
  return (
    <>
      <p>
        <br />
      </p>
      <h1>Boom</h1>
      <h2>Terms of Use</h2>
      <p>
        <br />
      </p>
      <p>Last Updated: April 24, 2023</p>
      <p>
        <br />
      </p>
      <h3>Welcome to Boom.</h3>
      <p>
        <br />
      </p>
      <p>
        At Boom, we’re on a mission to level the playing field for the 110+
        million renters in the US by making housing more flexible, affordable,
        and rewarding. We aim to do so with transparency, trust and a best-in
        class organization, products and features.
      </p>
      <p>
        Below is a formal description of what you can expect from using our
        service. We detail how the process works, including your rights as a
        user as well as our policies and procedures. Please read these terms in
        full as they contain important information on your legal rights,
        remedies and obligations.
      </p>
      <h3>Table of Contents</h3>
      <p>
        <br />
      </p>
      <ol id="l1">
        <li data-list-text="1.">
          <h4>Preamble</h4>
        </li>
        <li data-list-text="2.">
          <h4>Scope of Agreement</h4>
        </li>
        <li data-list-text="3.">
          <p>Privacy and Your Personal Information</p>
        </li>
        <li data-list-text="4.">
          <p>Description of the Services</p>
        </li>
        <li data-list-text="5.">
          <p>Registration</p>
        </li>
        <li data-list-text="6.">
          <p>Boom’s Services</p>
        </li>
        <li data-list-text="7.">
          <p>Fraud Review and Prevention</p>
        </li>
        <li data-list-text="8.">
          <p>Access</p>
        </li>
        <li data-list-text="9.">
          <p>Boom Offers</p>
        </li>
        <li data-list-text="10.">
          <h4>Social Media Sites</h4>
        </li>
        <li data-list-text="11.">
          <p>Consent to Communication</p>
        </li>
        <li data-list-text="12.">
          <h4>SMS Messaging</h4>
        </li>
        <li data-list-text="13.">
          <h4>Content and Use Restrictions</h4>
        </li>
        <li data-list-text="14.">
          <h4>Code of Conduct</h4>
        </li>
        <li data-list-text="15.">
          <h4>License to Your Content</h4>
        </li>
        <li data-list-text="16.">
          <h4>Termination</h4>
        </li>
        <li data-list-text="17.">
          <h4>Representations and Warranties</h4>
        </li>
        <li data-list-text="18.">
          <h4>License to Use the Platform; Ownership</h4>
        </li>
        <li data-list-text="19.">
          <h4>Apple/Google Requirements</h4>
        </li>
        <li data-list-text="20.">
          <h4>Indemnification</h4>
        </li>
        <li data-list-text="21.">
          <h4>Miscellaneous</h4>
        </li>
        <li data-list-text="22.">
          <h4>Questions?</h4>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <ol id="l2">
        <li data-list-text="1.">
          <h3>Preamble</h3>
          <p>
            <br />
          </p>
          <p>
            By using the Boom Pay, Inc. (“<u>Boom</u>”, “<u>we</u>”, “<u>us</u>
            ”, or “<u>our</u>
            <a href="https://www.stash.com/" target="_blank">
              ”) website (
            </a>
            www.boompay.app) (“<u>Website</u>”) or any other websites, or
            products, services and/or mobile applications owned or operated by
            Boom (collectively with the Website, the “<u>Platform</u>”), you are
            agreeing to these Terms of Use (these “<u>Terms</u>”), which we may
            update or amend from time to time as set forth herein.
          </p>
          <p>
            <br />
          </p>
          <p>
            <a href="mailto:hello@boompay.app" target="_blank">
              Boom provides services for renters. We facilitate certain aspects
              of the rental process. Any questions regarding these terms may be
              directed to{" "}
            </a>
            hello@boompay.app or via our chat functionality on the Boom website
            or in the
          </p>
          <p>
            Boom application. We may ame
            <span>
              nd this agreement from time to time and will post notice of the
              latest version to the website. The revised version will be
              effective immediately after we post it. Changes will not apply
              retroactively. Your continued use of the services following any
              update to this Agreement will be deemed acceptance of the updated
              Agreement. If you do not agree to the modified terms of this
              Agreement, you should discontinue use of the Services.
            </span>
          </p>
        </li>
        <li data-list-text="2.">
          <h3>Scope of Agreement</h3>
          <p>
            <br />
          </p>
          <p>
            By accessing and/or using the Platform, you agree to these Terms.
            These Terms apply to any use of the Platform including (1) when you
            register a user account;
          </p>
          <p>
            (2) when you sign up for or receive any products or services
            provided through the Platform (collectively, the “Services”) and (3)
            when you use the Platform as a guest.
          </p>
        </li>
        <li data-list-text="3.">
          <h3>Privacy and Your Personal Information</h3>
          <p>
            <br />
          </p>
          <p>
            <a href="https://www.boompay.app/legal" target="_blank">
              Protecting your personal information is a top priority for Boom.
              For information about our personal information practices, please
              read our{" "}
            </a>
            <a href="https://www.boompay.app/legal" target="_blank">
              Privacy{" "}
            </a>
            <span>Policy</span>, which is hereby incorporated into these Terms.
            This policy explains how we treat and guard your personal
            information when you use the Platform and Services. The policy may
            be changed from time to time; changes will be effective upon posting
            to the Site.
          </p>
        </li>
        <li data-list-text="4.">
          <h3>Description of the Services</h3>
          <p>
            <br />
          </p>
          <p>
            The following terms shall have the meanings set forth in this
            section, or in the section they first appear.
          </p>
          <ul id="l3">
            <li data-list-text="●">
              <p>
                “Content” means text, graphics, images, information or other
                materials accessible through the Platform or Services.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                “Landlord” means the owner or manager of a property who is
                legally responsible for receiving Rent Payments.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                “Rent Payment” means a payment by a tenant to a Landlord with
                whom they have lawfully executed lease, or proof of residency
                for the expressed and sole purpose of rent per the terms of that
                lease or agreement with the individual.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                “Linked Account” means an eligible checking account of which you
                are an authorized holder of record. Boom has partnered with
                Plaid to allow you to securely link accounts and will access the
                account and routing number from such accounts without storing
                personal or sensitive information on Boom servers.
              </p>
              <p>
                <br />
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="5.">
          <h3>Registration</h3>
          <p>
            <br />
          </p>
          <p>
            In order to access the Services, you must: 1) be over the age of 18,
            reside in the United States and have a non-VoIP, United States-based
            mobile number – Company in its sole discretion determines whether a
            mobile phone number is acceptable; 2) accept and agree to be bound
            by these Terms and our Privacy Policy; 3) complete registration with
            us to create a Boom account; 4) maintain your account in good
            standing; 5) provide all information requested by us, including but
            not limited to: your full legal name, phone number, valid email
            address, and Linked Account information. You represent and warrant
            that all information you provide to us is true, accurate, current
            and complete and agree not to misrepresent your identity in any way.
            You further agree to promptly notify us via the chat functionality
            within the Boom application or at
          </p>
          <p>
            <a href="https://www.boompay.app/legal" target="_blank">
              hello@boompay.app of any changes to personal information relevant
              to your account. Please see our{" "}
            </a>
            <a href="https://www.boompay.app/legal" target="_blank">
              Privacy{" "}
            </a>
            <span>Policy</span>{" "}
            <span>
              as it governs our collection, use, storage and disclosure of your
              personal information.
            </span>
          </p>
          <p>
            <a href="mailto:hello@boompay.app" target="_blank">
              You are solely responsible for maintaining the confidentiality of
              your account login information (SMS, email address and password).
              You agree not to authorize any other person to use your account
              for any purpose. Except as otherwise provided by applicable law,
              you are solely responsible for any actions, transactions or other
              activities performed using your account performed using your
              account. If you believe any of your account login information has
              been compromised, obtained or used by any other person, or you
              become aware of a breach of the security of the Services, you must
              notify us immediately via the chat functionality within the Boom
              application or at{" "}
            </a>
            <a href="mailto:hello@boompay.app" target="_blank">
              hello@boompay.app.
            </a>
          </p>
        </li>
        <li data-list-text="6.">
          <h3>Boom’s Services</h3>
          <p>
            <br />
          </p>
          <p>
            Boom provides financial services such as rent reporting and other
            products that may be offered in the future.
          </p>
          <p>
            <a href="https://www.boompay.app/legal" target="_blank">
              Boom provides reporting of rental payments to the major credit
              bureaus. Boom is able to provide rent reporting services for any
              tenant who is able to link their bank account and/or links their
              rental property management software account to Boom, in
              partnership with Boom-partnered Landlords, in order to verify
              in-scope rental payments. Renter may discontinue enrollment in
              Boom’s services at any time and can cancel his or her
              subscription. Depending on the contractual setup, fees may be
              charged to the renter, Boom-partnered Landlord, or both. More
              details on Boom’s BoomReport product may be found in Boom’s{" "}
            </a>
            <span>BoomReport</span>
            <a href="https://www.boompay.app/legal" target="_blank">
              {" "}
            </a>
            <a href="https://www.boompay.app/legal" target="_blank">
              Terms Of{" "}
            </a>
            <span>Use</span>.
          </p>
        </li>
        <li data-list-text="7.">
          <h3>Fraud Review and Prevention</h3>
          <p>
            <br />
          </p>
          <p>
            To prevent any attempts of fraud, Boom reserves the right to conduct
            a fraud review (“<u>Fraud Review</u>”) in our sole discretion on any
            payment made through the Services, and reject any payment we deem,
            in good faith and accordance with our fraud review, to be
            illegitimate. Such Fraud Review will be conducted by our internal
            Fraud Review team according to our protocol and may include, but is
            not limited to: validating sufficient balance in your Linked
            Account, validating your identity and the identity of your Landlord,
            requesting an executed copy of your lease or other agreement proving
            your residency.
          </p>
        </li>
        <li data-list-text="8.">
          <h3>Access</h3>
          <p>
            <br />
          </p>
          <p>
            Use of the Platform may require a compatible mobile device and
            Internet access. Your ability to use the Platform may be affected by
            performance of these items.
          </p>
          <p>
            You acknowledge and agree that all such system requirements, which
            may be changed from time to time, are your responsibility, and your
            mobile carrier’s standard charges, data rates and other fees may
            apply. Further, you agree to always use the most recent version of
            the Platform made available by Boom.
          </p>
        </li>
        <li data-list-text="9.">
          <h3>Boom Offers</h3>
          <p>
            <br />
          </p>
          <p>
            From time to time, Boom may present rewards or offers to members for
            using its service. Boom will denote any terms and conditions
            associated with such offers at the time they are presented. It is at
            Boom's absolute discretion to opine on whether what was offered has
            been properly met and offers can change from time to time without
            notice and at any point.
          </p>
          <p>You agree that you will not:</p>
          <ul id="l4">
            <li data-list-text="●">
              <p>
                Use any robot, spider, scraper, deep link or other similar
                automated data gathering or extraction tools, program, algorithm
                or methodology to access, acquire, copy or monitor the Platform
                or any portion of the Platform, without Boom’ express written
                consent, which may be withheld in Boom’ sole discretion;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Use or attempt to use any engine, software, tool, agent, or
                other device or mechanism (including without limitation
                browsers, spiders, robots, avatars or intelligent agents) to
                navigate or search the services, other than the search engines
                and search agents available through the Services and other than
                generally available commercially available web browsers;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Post or transmit any file which contains viruses, worms, Trojan
                horses or any other contaminating or destructive features, or
                that otherwise interfere with the proper working of the
                Platform; or
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Attempt to decipher, decompile, disassemble, or reverse-engineer
                any of the software comprising or in any way making up a part of
                the Platform.
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="10.">
          <h3>Social Media Sites</h3>
          <p>
            <br />
          </p>
          <p>
            Boom may provide experiences on social media platforms such as
            Facebook®, Twitter® and LinkedIn® that enable online sharing and
            collaboration among users who have registered to use them. Any
            content you post, such as pictures, information, opinions, or any
            personal information that you make available to other participants
            on these social platforms, is subject to the terms of use and
            privacy policies of those platforms. Please refer to those social
            media platforms to better understand your rights and obligations
            with regard to such content.
          </p>
        </li>
        <li data-list-text="11.">
          <h3>Consent to Communication</h3>
          <p>
            By using the Platform, you agree that we may communicate with you
            electronically regarding administrative, security and other issues
            relating to your use of the Platform, including but not limited to:
            the status of your rent payments, upcoming offers, reminders, fraud
            alerts etc. Communication will be made primarily through use of text
            messages to the mobile phone number used to register your Account.
            We may also contact you through your email address and push any
            notices, agreements, disclosures or other communications that we
            send to you electronically will satisfy any legal communication
            requirements, including that such communications be in writing.
          </p>
        </li>
        <li data-list-text="12.">
          <h3>SMS Messaging</h3>
          <p>
            <br />
          </p>
          <p>
            This section outlines your consent to receive texts and other
            communications from us, and your representation regarding the texts
            we send to others in relation to your payments. You also agree to
            receive messages from us via email. You can opt out of
            communications in ways that are specific to each method, such as by
            unsubscribing from emails or replying to text messages with ‘STOP.’
          </p>
          <h4>Messaging Rates</h4>
          <p>
            <br />
          </p>
          <p>
            Any text messages sent and/or received are subject to standard text
            messaging rates. Additionally, text messages sent and/or received
            may count towards any monthly quota, as applicable. Any users,
            whether sending or receiving text messages in connection with the
            Platform, should be aware that standard text messaging rates may
            apply. Please be aware that texting charges can fluctuate
            internationally. Text messaging rates can normally be found by
            asking your provider.
          </p>
          <h4>Consent to Receive SMS</h4>
          <p>
            When you provide us with your mobile number, either when you sign up
            for an Account or update your contact information, you consent to
            receiving text (SMS) messages and push notifications from us. These
            communications may include, but are not limited to, requests for
            secondary authentication, receipts, reminders, notifications
            regarding updates to your account or account support, and marketing
            or promotional communications. Please note that you are not required
            to consent to receive promotional texts or calls as a condition of
            using our Services. Call and text message communications may be
            generated by automatic telephone dialing systems, and standard
            message and data rates applied by your cell phone carrier may apply
            to the text messages we send you.
          </p>
          <p>
            If you provide us with the mobile number of any other person or
            entity when initiating or requesting a payment through our Service,
            you represent and warrant to us that the person or entity has
            consented to receive text messages from us related to that payment.
            Standard text messaging rates may apply based on your plan with your
            mobile carrier.
          </p>
          <h4>Opting-Out of SMS</h4>
          <p>
            <br />
          </p>
          <p>
            You may opt-out of receiving promotional email communications from
            us by following the unsubscribe options on such emails. To opt-out
            of any promotional phone calls, inform the caller that you do not
            wish to receive future promotional calls. You may opt-out of text
            messages from us by replying STOP or by changing your notification
            preferences in the app. Please note that opting out of receiving
            communications may impact your use of our Services.
          </p>
          <h4>Compatible Mobile Devices and Third-Party Carriers</h4>
          <p>
            <br />
          </p>
          <p>
            We do not warrant that our Services will be compatible with your
            mobile device or carrier. Your use of our Services may be subject to
            the terms of your agreements
          </p>
          <p>
            with your mobile device manufacturer or carrier. If you use a
            modified device to access our Services in a way that is contrary to
            the manufacturer's software or hardware guidelines, including
            disabling hardware or software controls (also known as
            “jailbroken”), you may not be able to use our Services.
          </p>
        </li>
        <li data-list-text="13.">
          <h3>Content and Use Restrictions</h3>
          <p>
            <br />
          </p>
          <p>
            You are solely responsible for the content, information, images,
            videos and text (“Content”) that you post, upload, transmit, publish
            or display on or through the Platform (hereinafter, “post”), or
            transmit to other users. You will not provide inaccurate, misleading
            or false information to Boom or to any other user. If information
            provided to Boom or another user subsequently becomes inaccurate,
            misleading or false, you will promptly notify us of such change. You
            will not post on the Platform, or transmit to other users, any
            defamatory, inaccurate, abusive, obscene, profane, offensive,
            sexually oriented, threatening, harassing, racially offensive, or
            illegal material, or any material that infringes or violates another
            party’s rights (including, but not limited to, intellectual property
            rights, and rights of privacy and publicity). Without limiting the
            previous sentence, you may not post any Content or use the Platform
            in any way that:
          </p>
          <ul id="l5">
            <li data-list-text="●">
              <p>
                Is patently offensive, such as Content that promotes racism,
                bigotry, hatred or physical harm of any kind against any group
                or individual;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Intimidates, threatens, or otherwise harasses other users of the
                Platform, or advocates harassment of another person;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Is false, misleading or promotes illegal activities or conduct
                that is abusive, threatening, obscene, defamatory or libelous;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                exploits people under the age of 18 in a sexual or violent
                manner, or attempts to collect personal information about any
                other user of the
              </p>
              <p>
                Platform or any third party without such user or third party’s
                informed consent;
              </p>
            </li>
            <li data-list-text="●">
              <p>contains sexually explicit Content;</p>
            </li>
            <li data-list-text="●">
              <p>
                provides instructional information about illegal activities such
                as making or buying illegal weapons, violating someone’s
                privacy, or providing or creating computer viruses;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                involves the transmission of “junk mail”, “chain letters,” or
                unsolicited mass mailing or “spamming”;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                use any robot, spider, site search/retrieval application, or
                other manual or automatic device or process to retrieve, index,
                “data mine”, or in any way reproduce or circumvent the
                navigational structure or presentation of the Platform or its
                contents;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                promotes an illegal or unauthorized copy of another person’s
                copyrighted work, such as providing pirated computer programs or
                links to them, providing information to circumvent
                manufacture-installed copy-protect devices, or providing pirated
                images, audio or video, or links to pirated images, audio or
                video files;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                contains or transmits viruses, corrupted data or other harmful,
                disruptive or destructive files or code;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                solicits personally identifiable information for commercial or
                unlawful purposes from other users;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                engages in commercial activities and/or sales including but not
                limited to contests, sweepstakes, barter, advertising, and
                pyramid schemes; or
              </p>
            </li>
            <li data-list-text="●">
              <p>
                infringes any third party’s copyrights or other rights
                (including but not limited to other intellectual property rights
                and privacy rights).
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="14.">
          <h3>Code of Conduct</h3>
          <p>
            Your use of the Platform, including but not limited to the Content
            you post on the Platform, must be in accordance with any and all
            applicable laws and regulations. You will only send messages via the
            Platform to people who have expressly agreed to receive such
            messages from you. Boom, has the right, but not the obligation, to
            monitor all conduct on and Content submitted to the Platform.
          </p>
        </li>
        <li data-list-text="15.">
          <h3>License to Your Content</h3>
          <p>
            <br />
          </p>
          <p>
            When you post, link or otherwise make available Content to the
            Platform, you grant us a nonexclusive, royalty-free, fully paid-up,
            perpetual, irrevocable, transferable and fully sublicensable right
            and license to use, reproduce, modify, adapt, publish, translate,
            create derivative works from, distribute, perform and display, and
            otherwise exploit such Content throughout the world in any manner or
            media, on or off the Service.
          </p>
        </li>
        <li data-list-text="16.">
          <h3>Termination</h3>
          <p>
            <br />
          </p>
          <ul id="l6">
            <li data-list-text="●">
              <p>
                Boom reserves the right to remove users' accounts from Boom at
                any time for any reason whatsoever, including but not limited to
                the following reasons: user attempts to submit fraudulent or
                altered documents, user does not pass our “Fraud Review”.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                If you breach any of the terms of these Terms, all licenses
                granted by Boom, including permission to use the Platform, will
                terminate automatically. Additionally, Boom may suspend,
                disable, or delete your account and/or the Platform (or any part
                of the foregoing) with or without notice, for any or no reason.
                If Boom deletes your account for any suspected breach of these
                Terms by you, you are prohibited from
              </p>
              <p>
                re-registering for the Platform under a different name or with a
                different mobile telephone number.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                <a href="mailto:hello@boompay.app" target="_blank">
                  You may cease use of the Platform at any time. However, we
                  will not automatically delete your account information in case
                  you decide you would like to rejoin the Platform. If you want
                  Boom to delete all of your account information, including your
                  address book information, you may do so in the app or by
                  emailing us with such a request at{" "}
                </a>
                hello@boompay.app.{" "}
                <span>
                  Boom will keep check out payment transaction history for at
                  least 7 years as necessary.
                </span>
              </p>
            </li>
            <li data-list-text="●">
              <p>
                All sections which by their nature should survive the
                termination of these Terms shall continue in full force and
                effect subsequent to and notwithstanding any termination of this
                Agreement by Boom or you. In the event of account deletion for
                any reason, Boom may, but is not obligated to, delete any of
                your Content (as defined in Section 5). Boom shall not be
                responsible for the deletion of (or failure to delete) such
                Content.
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="17.">
          <h3>Representations and Warranties</h3>
          <p>
            <br />
          </p>
          <p>
            You represent and warrant that: (a) you have the right to submit all
            Content and feedback to Boom and grant the licenses set forth in
            these Terms; (b) Boom will not need to obtain licenses from any
            third party or pay royalties to any third party to use the Content
            and feedback in accordance with these Terms; (c) the Content and
            feedback do not infringe any third party’s rights, including but not
            limited to intellectual property rights and privacy rights; and (d)
            the Content and feedback comply with these Terms and all applicable
            laws.
          </p>
        </li>
        <li data-list-text="18.">
          <h3>License to Use the Platform; Ownership</h3>
          <p>
            <br />
          </p>
          <ul id="l7">
            <li data-list-text="●">
              <h4>
                License
                <span>
                  . Subject to your compliance with these Terms, Boom grants to
                  you a limited, non-exclusive, non-transferable license to
                  access and use the Platform during the term of these Terms
                  solely for your own personal,
                </span>
              </h4>
              <p>
                non-commercial purposes. This license is personal to you and may
                not be assigned or sub licensed to anyone else.
              </p>
            </li>
            <li data-list-text="●">
              <h4>
                Restrictions
                <span>
                  . You will not reproduce, copy, transfer, give access to,
                  distribute, sell, rent, lease, assign, sublicense, create
                  derivative works from, decompile, reverse engineer, or
                  disassemble the Platform or any part thereof. You will not
                  take any measures to interfere with or damage the Platform or
                  any part thereof.
                </span>
              </h4>
            </li>
            <li data-list-text="●">
              <h4>
                Ownership
                <span>
                  . You acknowledge and agree that nothing in these Terms
                  conveys to you any ownership, intellectual property rights or
                  other proprietary interest in or relating to the Platform or
                  any other Boom products or services, or any modifications or
                  derivative works of any of the foregoing. The Platform is
                  licensed, not sold, to you and is owned by Boom and its
                  licensors. Except as expressly set forth herein, Boom reserves
                  all right, title and interest, including all intellectual
                  property and other rights, in and to the Platform and all
                  other Boom products and services. You shall not obscure or
                  alter or remove any patent, copyright, trademark or other
                  proprietary notice or legend contained on or in the Platform.
                </span>
              </h4>
            </li>
            <li data-list-text="●">
              <h4>
                Feedback
                <span>
                  . You agree that any suggestions, comments, ideas,
                  corrections, improvements, feedback or other information you
                  provide Boom (collectively, “Feedback”) shall be the sole and
                  exclusive property of Boom and you hereby assign, for no
                  additional consideration, all right, title and interest in and
                  to such feedback to Boom. Boom will be free to use, disclose,
                  reproduce, license and otherwise distribute the feedback,
                  including but not limited to in connection with any product or
                  service, without any obligations or restrictions of any kind.
                </span>
              </h4>
            </li>
          </ul>
        </li>
        <li data-list-text="19.">
          <h3>Apple/Google Requirements</h3>
          <p>
            These Terms apply to your use of all the Platform, including the iOS
            and Android applications available via the Apple, Inc. (“Apple”) App
            Store (the “Application”) or via the Google Play Store ("Google")
            but the following additional terms also apply to the Platform:
          </p>
          <ul id="l8">
            <li data-list-text="●">
              <p>
                Both you and Boom acknowledge that the Terms are concluded
                between you and Boom only, and not with Apple or Google and that
                Apple or Google is not responsible for the Platform or the
                Content;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                The Platform is licensed to you on a limited, non-exclusive,
              </p>
              <p>
                non-transferrable, non-sublicensable basis, solely to be used in
                connection with the Services for your private, personal,
                non-commercial use, subject to all the terms and conditions of
                these Terms as they are applicable to the Services;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                You will only use the Platform in connection with an Apple or
                Android device that you own or control;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                You acknowledge and agree that Apple or Google has no obligation
                whatsoever to furnish any maintenance and support services with
                respect to the Platform;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                In the event of any failure of the Platform to conform to any
                applicable warranty, including those implied by law, you may
                notify Apple or Google of such failure; upon notification,
                Apple’s or Google's sole warranty obligation to you will be to
                refund to you the purchase price, if any, of the Platform;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                You acknowledge and agree that Boom, and not Apple or Google, is
                responsible for addressing any claims you or any third party may
                have in relation to the Platform;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                You acknowledge and agree that, in the event of any third party
                claim that the Platform or your possession and use of the
                Platform infringes that third party’s intellectual property
                rights, Boom, and not Apple or Google, will be
              </p>
              <p>
                responsible for the investigation, defense, settlement and
                discharge of any such infringement claim;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                You represent and warrant that you are not located in a country
                subject to a U.S. Government embargo, or that has been
                designated by the U.S. Government as a “terrorist supporting”
                country, and that you are not listed on any U.S. Government list
                of prohibited or restricted parties;
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Both you and Boom acknowledge and agree that, in your use of the
                Platform, you will comply with any applicable third party terms
                of agreement which may affect or be affected by such use; and
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Both you and Boom acknowledge and agree that Apple and Apple’s
                subsidiaries or Google and Google's subsidiaries are third party
                beneficiaries of these Terms, and that upon your acceptance of
                these Terms, Apple or Google will have the right (and will be
                deemed to have accepted the right) to enforce these Terms
                against you as the third party beneficiary hereof.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                In the event you use the Platform to provide you with real-time
                route guidance, YOUR USE OF THIS REAL TIME ROUTE GUIDANCE
                APPLICATION IS AT YOUR SOLE RISK. LOCATION DATA MAY NOT BE
                ACCURATE.
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="20.">
          <h3>Indemnification</h3>
          <p>
            <br />
          </p>
          <p>
            You agree to indemnify and hold harmless Boom, its subsidiaries,
            affiliates, related parties, officers, directors, employees, agents,
            suppliers, independent contractors, advertisers, partners and
            co-branders from any loss, damages, or costs, including without
            limitation reasonable attorneys’ fees, resulting from any third
            party claim, action, or demand resulting from your use of or access
            to the
          </p>
          <p>
            Platform, your violation of any third party proprietary or other
            rights, or breach of these Terms.
          </p>
          <p>Disclaimer of Warranties; Limitation of Liability</p>
          <p>
            <br />
          </p>
          <ul id="l9">
            <li data-list-text="●">
              <p>
                YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER
                USERS OF THE SERVICE. YOU UNDERSTAND THAT Boom IS NOT AND SHALL
                NOT BE RESPONSIBLE OR LIABLE FOR ANY ACT OR OMISSION BY ANY USER
                OF THE SERVICE, WHETHER ON OR OFF THE SERVICE. Boom MAKES NO
                REPRESENTATIONS OR WARRANTIES AS TO THE SERVICE, CONTENT AND THE
                CONDUCT OF USERS OF THE SERVICE.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Boom has no obligation to screen or monitor any Content (as
                defined in Section 5) and does not guarantee that any Content
                available on the Platform complies with these Terms or is
                suitable for all users.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Boom PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE”
                BASIS. YOU THEREFORE USE THE SERVICE AT YOUR OWN RISK. Boom
                EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT, AND ANY OTHER WARRANTY THAT MIGHT ARISE UNDER
                ANY LAW.
              </p>
              <p>
                Without limiting the foregoing, Boom makes no representations or
                warranties: (a) that the Service will be permitted in your
                jurisdiction; (b) that the Service will be uninterrupted or
                error-free; (c) concerning any Content submitted by any user;
                (d) concerning any third party’s use of Content that you submit;
                (e) that any Content you submit will be made available on the
                Platform or will be stored by Boom; (f) that Boom will continue
                to support
              </p>
              <p>
                any particular feature of the Platform; (g) Concerning sites and
                resources outside of the Platform, even if linked to, from or
                through from the Platform.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                IN NO EVENT WILL Boom BE LIABLE FOR ANY DAMAGES WHATSOEVER,
                WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, EXEMPLARY,
                COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF
                OR RELATING TO THE SERVICE, OR ANY USE OF THE SERVICE BY YOU OR
                ANY THIRD PARTY, INCLUDING WITHOUT LIMITATION, BODILY INJURY,
                DEATH, EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING
                FROM COMMUNICATIONS OR MEETINGS WITH OTHER USERS OF THE SERVICE
                OR PERSONS YOU MEET THROUGH THE SERVICE. YOU AGREE TO TAKE
                REASONABLE PRECAUTIONS IN ALL INTERACTIONS WITH OTHER USERS OF
                THE SERVICE, PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN
                PERSON. In
              </p>
              <p>
                some jurisdictions, it is not permitted to limit certain types
                of liability and
              </p>
              <p>
                therefore such limitations to the extent they exclude such
                liability may not apply to you. IN SUCH JURISDICTIONS, Boom’S
                LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY
                APPLICABLE LAW.
              </p>
              <p>ARBITRATION AND CLASS ACTION WAIVER.</p>
              <p>
                <br />
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Informal Process First. You agree that in the event of any
                dispute between you and Boom, you will first contact Boom and
                make a good faith sustained effort to resolve the dispute before
                resorting to arbitration under this Section.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Binding Arbitration. Any dispute or claim arising in any way
                from your use of the Service, except for disputes relating to
                the infringement of our
              </p>
              <p>
                intellectual property rights or the access or use of the
                Platform in violation of these Terms, will be resolved by
                binding arbitration, rather than in court, but you may assert
                claims in small claims court if your claims qualify.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                No Judge or Jury. There is no judge or jury in arbitration, and
                court review of an arbitration award is limited. However, an
                arbitrator can award on an individual basis the same damages and
                relief as a court (including injunctive and declaratory relief
                or statutory damages), and must follow the terms of these Terms
                as a court would.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                <a href="https://www.adr.org/" target="_blank">
                  Arbitrator and Rules. The arbitration will be conducted before
                  a neutral single arbitrator, whose decision will be final and
                  binding, and the arbitral proceedings shall be governed by the
                  AAA Commercial Arbitration Rules, Consumer Due Process
                  Protocol, and Supplementary Procedures for Resolution of
                  Consumer Related Disputes. These rules can be found on the AAA
                  website at{" "}
                </a>
                www.adr.org<span>.</span>
              </p>
            </li>
            <li data-list-text="●">
              <p>
                <a href="mailto:hello@boompay.app" target="_blank">
                  Starting an Arbitration. To begin an arbitration proceeding,
                  you must send us a notice of dispute, in writing, setting
                  forth your name, address and contact information, the facts of
                  the dispute and relief requested. You must send your notice of
                  legal dispute to us at the following address:{" "}
                </a>
                <a href="mailto:hello@boompay.app" target="_blank">
                  hello@boompay.app
                </a>
                . Boom will send any notice of dispute to you at the contact
                information we have for you.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Format of Proceedings. The arbitration shall be conducted, at
                the option of the party seeking relief, by telephone, online, or
                based solely on written submissions.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Fees. If you initiate arbitration, your arbitration fees will be
                limited to the filing fee set forth in the AAA’s Consumer
                Arbitration Rules. Unless the arbitrator finds the arbitration
                was frivolous or brought for an improper purpose, Boom will pay
                all other AAA and arbitrator’s fees and expenses.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Individual Basis. To the fullest extent permitted by applicable
                law, you and Boom each agree that any dispute resolution
                proceeding will be conducted only on an individual basis and not
                in a class, consolidated or representative action. If for any
                reason a claim proceeds in court rather than in arbitration, you
                and Boom each waive any right to a jury trial. As a result,
                PROCEEDINGS TO RESOLVE OR LITIGATE A DISPUTE IN ANY FORUM WILL
                BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Limitation Period. In no event shall any claim, action or
                proceeding by you or Boom be instituted more than one (1) year
                after the cause of action arose.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Enforcement. Any judgment on the award rendered by the
                arbitrator may be entered in any court of competent
                jurisdiction. The United Nations Conventions on Contracts for
                the International Sale of Goods shall have no applicability.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Invalidity. If a court of competent jurisdiction finds the
                foregoing arbitration provisions invalid or inapplicable, you
                and Boom each agree to the exclusive jurisdiction of the Federal
                and State courts located in Suffolk County, Massachusetts, and
                you and Boom each agree to submit to the exercise of personal
                jurisdiction of such courts for the purposes of litigating any
                applicable dispute or claim.
              </p>
            </li>
            <li data-list-text="●">
              <p>
                Opting Out. If you do not want to arbitrate disputes with Boom
                and you are an individual, you may opt out of this arbitration
                agreement by sending an email to within thirty (30) days of the
                first of the date you access or use the Service.
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="21.">
          <h3>Miscellaneous</h3>
          <p>
            These Terms are the complete and exclusive agreement between you and
            Boom with respect to the subject matter of these Terms, and they
            supersede all prior or contemporaneous, oral or written, proposals,
            understandings, representations, conditions, warranties, and all
            other communications between you and Boom with respect to the
            subject matter of these terms. These Terms, and any rights and
            licenses granted hereunder, may not be transferred or assigned by
            you, but may be assigned by Boom without restriction. These Terms
            may not be explained or supplemented by any prior course of dealings
            or trade by custom or usage.
          </p>
          <p>
            These Terms shall be governed by the laws of the Southern District
            of New York, notwithstanding any principles of conflicts of law. The
            section headings used herein are for convenience only and will not
            affect the interpretation of these Terms. No waiver by either party
            of any breach or default hereunder will be deemed to be a waiver of
            any preceding or subsequent breach or default. If any provision of
            these Terms will be unlawful, void, or for any reason unenforceable,
            then that provision will be deemed severable from these Terms and
            will not affect the validity and enforceability of any remaining
            provisions. No agency, partnership, joint venture, or employment
            relationship is created as a result of the Terms and neither party
            has any authority of any kind to bind the other in any respect.
          </p>
        </li>
        <li data-list-text="22.">
          <h3>Questions?</h3>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>
        <a href="mailto:hello@boompay.app" target="_blank">
          Any questions, complaints or claims with respect to the Service should
          be sent via the chat functionality within the Boom application or to{" "}
        </a>
        <a href="mailto:hello@boompay.app" target="_blank">
          hello@boompay.app.
        </a>
      </p>
    </>
  );
};
