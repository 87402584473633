// @ts-nocheck
import React, { useEffect } from "react";
import {
  usePlaidLink,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkError,
  PlaidLinkOptionsWithLinkToken,
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
} from "react-plaid-link";

import { logEvent, logSuccess, logExit } from "./util";
// import { exchangeToken, setItemState } from "./services/api";
import { useLink, useErrors } from "./services";
import api from "../api";
import { useCustomer } from "../providers/customer";
// import { postEvent } from "src/helpers/postEvent";

// Uses the usePlaidLink hook to manage the Plaid Link creation.  See https://github.com/plaid/react-plaid-link for full usage instructions.
// The link token passed to usePlaidLink cannot be null.  It must be generated outside of this component.  In this sample app, the link token
// is generated in the link context in client/src/services/link.js.

export default function LaunchLink(props) {
  const { deleteLinkToken } = useLink();
  const { setError } = useErrors();

  const {
    enrollmentCase,
    updateEnrollmentCase,
    setEnrollmentCase,
    fetchPaymentInstruments,
    setLoading,
  } = useCustomer();

  function exchangeToken(metadata: PlaidLinkOnSuccessMetadata) {
    setLoading(true);
    return api
      .exchangePlaidPublicToken(metadata)
      .then(api.pickData)
      .then(({ bankAccount }) => {
        if (props.settingsUpdate) {
          props.settingsUpdate({
            bankAccount,
          });
        } else {
          updateEnrollmentCase({ bankAccount });
          setEnrollmentCase({ ...enrollmentCase, bankAccount });
          props.setToken("");
        }
      })
      .then(() => {
        fetchPaymentInstruments();
      })
      .then(() => setLoading(false));
  }

  // define onSuccess, onExit and onEvent functions as configs for Plaid Link creation
  const onSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    // log and save metatdata
    logSuccess(metadata, props.userId);
    // postEvent({
    //   type: "analytics",
    //   name: "submit: link bank",
    // });
    if (props.itemId != null) {
      deleteLinkToken(null, props.itemId);
    } else {
      // call to Plaid api endpoint: /item/public_token/exchange in order to obtain access_token which is then stored with the created item
      await exchangeToken(metadata);
    }
    // resetError();
    // deleteLinkToken(props.userId, null);
  };

  const onExit = async (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata
  ) => {
    // log and save error and metatdata
    logExit(error, metadata, props.userId);
    // postEvent({
    //   type: "analytics",
    //   name: "submit: link bank fail/error",
    // });
    if (error != null) {
      setError(error.error_code, error.display_message || error.error_message);
    }
  };

  const onEvent = async (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata
  ) => {
    // handle errors in the event end-user does not exit with onExit function error enabled.
    if (eventName === "ERROR" && metadata.error_code != null) {
      setError(metadata.error_code, " ");
    }
    if (eventName === "EXIT") {
      props.setToken("");
    }
    logEvent(eventName, metadata);
  };

  const config: PlaidLinkOptionsWithLinkToken = {
    onSuccess,
    onExit,
    onEvent,
    token: props.token,
  };

  // if (props.isOauth) {
  //   config.receivedRedirectUri = window.location.href; // add additional receivedRedirectUri config when handling an OAuth reidrect
  // }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    // initiallizes Link automatically
    if (ready) {
      // regular, non-OAuth case:
      // set link token, userId and itemId in local storage for use if needed later by OAuth

      // localStorage.setItem(
      //   "oauthConfig",
      //   JSON.stringify({
      //     userId: props.userId,
      //     itemId: props.itemId,
      //     token: props.token,
      //   })
      // );

      open();
    }
  }, [ready, open]);

  return <></>;
}
